import { useSearchParams } from "react-router-dom";
import { UNSAFE_NavigationContext } from "react-router";
import { History } from "@remix-run/router";
import { useContext } from "react";

type StateValue = string;

export function useUrlState(
  queryParameterName: string,
  initialState: StateValue,
  historyPushInsteadOfReplace: boolean = false
): [StateValue, (newValue: StateValue) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const { navigator } = useContext(UNSAFE_NavigationContext);
  const value = searchParams.get(queryParameterName) ?? initialState;

  const setValue = (newValue: StateValue) => {
    /*
     * Unfortunately the callback version of setSearchParams() immediately calls the callback with the old search
     * params. So does not help to prevent race conditions when updating multiple search params in parallel. Because of
     * that we need to use this history hack here.
     */
    const currentSearchParams = new URLSearchParams(
      (navigator as History).location.search
    );
    if (
      newValue === (currentSearchParams.get(queryParameterName) ?? undefined)
    ) {
      return;
    }

    if (newValue === initialState) {
      currentSearchParams.delete(queryParameterName);
    } else {
      currentSearchParams.set(queryParameterName, newValue);
    }
    setSearchParams(
      currentSearchParams,
      historyPushInsteadOfReplace
        ? {}
        : {
            replace: true,
          }
    );
  };
  return [value, setValue];
}

export function useBooleanUrlState(
  queryParameterName: string,
  initialValue: boolean = false,
  historyPushInsteadOfReplace: boolean = false
): [boolean, (newValue: boolean) => void] {
  const [value, setValue] = useUrlState(
    queryParameterName,
    String(initialValue),
    historyPushInsteadOfReplace
  );

  return [
    value === "true",
    (newValue) => {
      setValue(String(newValue));
    },
  ];
}

export function useNumberUrlState(
  queryParameterName: string,
  initialValue: number,
  historyPushInsteadOfReplace: boolean = false
): [number, (newValue: number) => void] {
  const [value, setValue] = useUrlState(
    queryParameterName,
    String(initialValue),
    historyPushInsteadOfReplace
  );

  return [
    Number(value),
    (newValue) => {
      setValue(String(newValue));
    },
  ];
}

export function useObjectUrlState<T>(
  queryParameterName: string,
  initialValue: T,
  historyPushInsteadOfReplace: boolean = false
): [T, (newValue: T) => void] {
  const [value, setValue] = useUrlState(
    queryParameterName,
    JSON.stringify(initialValue),
    historyPushInsteadOfReplace
  );

  return [
    JSON.parse(value) as T,
    (newValue) => {
      setValue(JSON.stringify(newValue));
    },
  ];
}
