import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConnectionLogsQueryVariables = Types.Exact<{
  deviceName: Types.Scalars['String'];
}>;


export type ConnectionLogsQuery = { __typename?: 'Query', connectionLogEntries?: { __typename?: 'ConnectionLogEntryCursorConnection', totalCount: number, edges?: Array<{ __typename?: 'ConnectionLogEntryEdge', node?: { __typename?: 'ConnectionLogEntry', id: string, timestamp: string, statusCode: number, method: string, remoteAddress: string, command: string } | null } | null> | null, pageInfo: { __typename?: 'ConnectionLogEntryPageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } | null };


export const ConnectionLogsDocument = gql`
    query ConnectionLogs($deviceName: String!) {
  connectionLogEntries(last: 5, sensor: $deviceName) {
    edges {
      node {
        id
        timestamp
        statusCode
        method
        remoteAddress
        command
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useConnectionLogsQuery__
 *
 * To run a query within a React component, call `useConnectionLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionLogsQuery({
 *   variables: {
 *      deviceName: // value for 'deviceName'
 *   },
 * });
 */
export function useConnectionLogsQuery(baseOptions: Apollo.QueryHookOptions<ConnectionLogsQuery, ConnectionLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectionLogsQuery, ConnectionLogsQueryVariables>(ConnectionLogsDocument, options);
      }
export function useConnectionLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectionLogsQuery, ConnectionLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectionLogsQuery, ConnectionLogsQueryVariables>(ConnectionLogsDocument, options);
        }
export type ConnectionLogsQueryHookResult = ReturnType<typeof useConnectionLogsQuery>;
export type ConnectionLogsLazyQueryHookResult = ReturnType<typeof useConnectionLogsLazyQuery>;
export type ConnectionLogsQueryResult = Apollo.QueryResult<ConnectionLogsQuery, ConnectionLogsQueryVariables>;