import React from "react";

interface Props {}

export const SensorGroupTreeHeader: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = () => {
  return (
    <div>
      <h2 className="text-2xl font-semibold leading-tight">Sensor Groups</h2>
    </div>
  );
};
