import React, { useState, useRef, useEffect } from "react";
import { DeleteUserPanel } from "./component/DeleteUserPanel";
import {
  SuccessAlert,
  SuccessAlertDuration,
} from "../../component/SuccessAlert";
import { ChangeUserPasswordPanel } from "./component/ChangeUserPasswordPanel";
import { useMatch, useNavigate } from "react-router";
import { gql } from "@apollo/client";
import {
  useDeleteUserMutation,
  useChangePasswordForUserMutation,
  useResetTwoFactorAuthForUserMutation,
  useChangeRolesForUserMutation,
} from "./UserDetailsScene.generated";
import {
  UserRowFragment,
  useUserDetailQuery,
} from "../../graphql/User.generated";
import { UserInfoPanel } from "./component/UserInfoPanel";
import { Panel } from "../../component/Panel";
import { Button } from "../../component/Button";
import { ApolloErrorList } from "../../component/ApolloErrorList";
import {
  SearchableSelectInput,
  SelectOption,
} from "../../component/Form/SearchableSelectInput";

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteRegularUser(input: { id: $id }) {
      user {
        id
      }
    }
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation changePasswordForUser($id: ID!, $password: String!) {
    changePasswordForUser(input: { id: $id, password: $password }) {
      user {
        id
      }
    }
  }
`;

export const CHANGE_USER_ROLES = gql`
  mutation changeRolesForUser($id: ID!, $roles: Iterable!) {
    changeRolesForUser(input: { id: $id, roles: $roles }) {
      user {
        id
        roles
      }
    }
  }
`;

export const RESET_USER_2FA = gql`
  mutation resetTwoFactorAuthForUser($id: ID!) {
    resetTwoFactorAuthForUser(input: { id: $id }) {
      user {
        id
      }
    }
  }
`;

export const UserDetailsScene: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const [successAlertDisplayed, setSuccessAlertDisplayed] = useState(false);
  const [successAlertMessage, setSuccessAlertMessage] = useState("");
  const navigate = useNavigate();
  const match = useMatch("/users/:userId");
  const userId = decodeURIComponent(match?.params.userId ?? "");

  const availableRoles = [
    { value: ["ROLE_USER"], label: "User" },
    { value: ["ROLE_ADMIN", "ROLE_USER"], label: "Admin" },
  ];
  const [roles, setRoles] = useState<SelectOption>(availableRoles[0]);

  const {
    data,
    error: userQueryError,
    loading,
    refetch,
  } = useUserDetailQuery({
    variables: { id: userId },
    onCompleted: (loadedData) => {
      const currentUserRole = loadedData?.user?.roles.includes("ROLE_ADMIN")
        ? availableRoles[1]
        : availableRoles[0];

      setRoles(currentUserRole);
    },
  });

  const [
    deleteUser,
    { error: deleteUserError, loading: loadingPasswordChange },
  ] = useDeleteUserMutation();
  const [changeUserPassword, { error: passwordChangeError }] =
    useChangePasswordForUserMutation();
  const [resetTwoFactorAuth, { error: resetTwoFactorAuthForUserError }] =
    useResetTwoFactorAuthForUserMutation();
  const [changeUserRoles] = useChangeRolesForUserMutation();

  const notificationTimer = useRef<number | null>(null);

  // Release timeout on component unmount
  useEffect(
    () => () => {
      if (notificationTimer.current) {
        window.clearTimeout(notificationTimer.current);
      }
    },
    []
  );

  const onUserDelete = (user: UserRowFragment) => {
    deleteUser({
      variables: { id: user.id },
    })
      .then(() => {
        navigate("/users");
      })
      .catch(() => {
        // Error will be displayed below
      });
  };

  const onUserRoleChange = (user: UserRowFragment) => {
    changeUserRoles({
      variables: { id: user.id, roles: roles.value },
      optimisticResponse: {
        __typename: "Mutation",
        changeRolesForUser: {
          user: {
            __typename: "User",
            id: user.id,
            roles: roles.value,
          },
        },
      },
    }).then(() => {
      setSuccessAlertMessage("Roles changed");
      setSuccessAlertDisplayed(true);
      notificationTimer.current = window.setTimeout(() => {
        setSuccessAlertDisplayed(false);
      }, SuccessAlertDuration.ALERT_DURATION_MEDIUM);
    });
  };

  const onUserPasswordChange = (password: string) => {
    changeUserPassword({ variables: { id: userId, password } })
      .then(() => {
        setSuccessAlertMessage("Password changed");
        setSuccessAlertDisplayed(true);
        notificationTimer.current = window.setTimeout(() => {
          setSuccessAlertDisplayed(false);
        }, SuccessAlertDuration.ALERT_DURATION_MEDIUM);
      })
      .catch(() => {
        // Error will be displayed below
      });
  };

  const onUser2FaReset = () => {
    resetTwoFactorAuth({ variables: { id: userId } })
      .then(() => {
        setSuccessAlertMessage("Two Factor Authentication reset");
        setSuccessAlertDisplayed(true);
        notificationTimer.current = window.setTimeout(() => {
          setSuccessAlertDisplayed(false);
        }, SuccessAlertDuration.ALERT_DURATION_MEDIUM);
      })
      .catch(() => {
        // Error will be displayed below
      })
      .finally(refetch);
  };

  if (userQueryError) {
    return <ApolloErrorList error={userQueryError} />;
  }

  if (deleteUserError) {
    return <ApolloErrorList error={deleteUserError} />;
  }

  if (resetTwoFactorAuthForUserError) {
    return <ApolloErrorList error={resetTwoFactorAuthForUserError} />;
  }

  if (loading || !data?.user) {
    return <span>Loading…</span>;
  }

  const user = data.user;

  return (
    <>
      <SuccessAlert
        show={successAlertDisplayed}
        message={successAlertMessage}
        onDismiss={() => {
          setSuccessAlertDisplayed(false);
        }}
      />
      <div className="container mx-auto flex flex-col">
        <div className="flex-shrink-0">
          <UserInfoPanel user={data?.user} />
        </div>
        <div className="flex-shrink-0">
          <ChangeUserPasswordPanel
            onUserPasswordChange={onUserPasswordChange}
            disabled={loadingPasswordChange}
            errorMessages={
              !passwordChangeError
                ? []
                : passwordChangeError.graphQLErrors.map(
                    ({ message }) => message
                  )
            }
          />
          <Panel overflow={"auto"}>
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-3">
                Change User Role
              </h3>
              <SearchableSelectInput
                id="role"
                value={roles}
                onChange={(selectedRole) => setRoles(selectedRole)}
                options={availableRoles}
              />
              <div className="mt-5">
                <Button
                  wide={true}
                  onClick={() => {
                    onUserRoleChange(user);
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Panel>
        </div>
        {data.user.twoFactorAuthEnabled && (
          <div className="flex-shrink-0">
            <Panel>
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Reset Two Factor Authentication
                </h3>
                <div className="mt-2 text-sm leading-5 text-gray-500">
                  <p>
                    Reset this user's Two Factor Authentication configuration.
                    They will have to set it up again on their next login.
                  </p>
                </div>
                <div className="mt-5">
                  <Button
                    buttonStyle="alert"
                    onClick={() => {
                      onUser2FaReset();
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </Panel>
          </div>
        )}
        <div className="flex-shrink-0">
          <DeleteUserPanel user={user} onUserDelete={onUserDelete} />
        </div>
      </div>
    </>
  );
};
