import React from "react";

interface Props {
  roles: string[];
}

export const UserRoleBadge: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ roles }) => {
  let roleName = "Unknown";

  if (roles.includes("ROLE_SUPER_ADMIN")) {
    roleName = "Superadmin";
  } else if (roles.includes("ROLE_ADMIN")) {
    roleName = "Admin";
  } else {
    roleName = "User";
  }

  return (
    <span className="inline-flex items-center text-sm font-medium leading-5 text-gray-800">
      {roleName}
    </span>
  );
};
