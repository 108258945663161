import React from "react";
import { XYCoord } from "react-dnd";
import { useDragLayer } from "../../../../../hook/dragAndDrop/useDragLayer";

interface Props {}

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  initialMouseOffset: XYCoord | null
) {
  if (!initialOffset || !currentOffset || !initialMouseOffset) {
    return {
      display: "none",
    };
  }

  let { x, y } = currentOffset;
  const grabXOffset = initialMouseOffset.x - initialOffset.x;

  const transform = `translate(${grabXOffset + x}px, ${y}px) translateX(-50%)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export const SensorGroupDragLayer: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = () => {
  const { item, initialOffset, currentOffset, initialMouseOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      initialMouseOffset: monitor.getInitialClientOffset(),
    }));

  return (
    <div
      style={{
        position: "fixed",
        pointerEvents: "none",
        zIndex: 100,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      {item?.type === "SENSOR_GROUP" && (
        <div
          className="bg-gray-200 inline-block x-4 py-2 px-8"
          style={getItemStyles(
            initialOffset,
            currentOffset,
            initialMouseOffset
          )}
        >
          {item.sensorGroupName}
        </div>
      )}
    </div>
  );
};
