import React, { useEffect, useState } from "react";
import { LoginPanel } from "../LoginPanel";
import QRCode from "qrcode.react";
import { base32 } from "rfc4648";

interface Props {
  onNext: (secret: string) => void;
}

export const SetupQrCode: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ onNext }) => {
  const [secret, setSecret] = useState<string | undefined>();
  useEffect(() => {
    const array = new Uint32Array(10);
    window.crypto.getRandomValues(array);
    setSecret(base32.stringify(array));
  }, []);

  return (
    <LoginPanel>
      <div>
        <h3 className="text-2xl text-center mb-5">
          Connect Your Authenticator App
        </h3>
        {secret && (
          <div className="flex justify-center">
            <QRCode
              value={`otpauth://totp/VPN%20Control%20Server?secret=${secret}&issuer=Hella%20Aglaia`}
              size={200}
              level="H"
              fgColor="#363847"
            />
          </div>
        )}
        <p className="text-center mt-5">
          Please use your authenticator app now to scan the code above.
        </p>
      </div>
      <div className="mt-5 text-center">
        <button
          className="w-full px-4 py-2 font-bold text-white bg-sunglow-500 rounded-full hover:bg-sunglow-600 focus:outline-none focus:shadow-outline"
          disabled={!secret}
          onClick={() => {
            if (secret) {
              onNext(secret);
            }
          }}
        >
          Next
        </button>
      </div>
    </LoginPanel>
  );
};
