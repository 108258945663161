import { gql } from "@apollo/client";
import React from "react";
import { useSensorFirmwareNamesQuery } from "./SensorFirmwareSelector.generated";
import {
  SearchableSelectInput,
  SelectOption,
} from "./Form/SearchableSelectInput";
import { nodesFromCollectionQuery } from "../helper/nodesFromCollectionQuery";

export const SENSOR_FIRMWARE_NAMES = gql`
  query SensorFirmwareNames {
    sensorFirmware {
      edges {
        node {
          name
        }
      }
    }
  }
`;

interface Props {
  firmwareName: string | null;
  onChangeFirmwareName: (name: string) => void;
}

export const SensorFirmwareSelector: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ firmwareName, onChangeFirmwareName }) => {
  const { data } = useSensorFirmwareNamesQuery();

  if (!data || !data.sensorFirmware) {
    return null;
  }

  const selectOptions: SelectOption[] = nodesFromCollectionQuery(
    data.sensorFirmware
  ).map((firmware) => ({
    label: firmware.name,
    value: firmware.name,
  }));

  const selectedValue =
    firmwareName === null ? null : { label: firmwareName, value: firmwareName };

  return (
    <SearchableSelectInput
      value={selectedValue}
      onChange={(option) => onChangeFirmwareName(option.value)}
      options={selectOptions}
    />
  );
};
