import React from "react";
import { UserRowFragment } from "../../../graphql/User.generated";
import { Panel } from "../../../component/Panel";
import { Button } from "../../../component/Button";
import { useConfirmationDialog } from "../../../hook/useConfirmationDialog";

interface Props {
  user: UserRowFragment;
  onUserDelete: (user: UserRowFragment) => void;
}

export const DeleteUserPanel: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ user, onUserDelete }) => {
  const message = `Are you sure you want to delete the user "${user.username}"? The user will be permanently removed. This action cannot be undone.`;
  const { showConfirmationDialog } = useConfirmationDialog(
    {
      title: "Delete User",
      message,
      confirmButtonText: "Delete",
      onConfirm: () => {
        onUserDelete(user);
      },
    },
    [user, onUserDelete]
  );

  return (
    <Panel>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Delete user
        </h3>
        <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
          <p>
            Once you delete a user, they will be permanently removed from the
            system.
          </p>
        </div>
        <div className="mt-5">
          <Button
            buttonStyle="alert"
            onClick={(event) => {
              event.preventDefault();
              showConfirmationDialog();
            }}
          >
            Delete User
          </Button>
        </div>
      </div>
    </Panel>
  );
};
