import React from "react";
import { ModalDialog } from "../../../component/ModalDialog";
import { AddCommentForm } from "./AddCommentForm";

interface Props {
  show: boolean;
  onConfirm: (comment: string) => void;
  onCancel: () => void;
}

export const CommentDialog: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ show, onConfirm, onCancel }) => {
  return (
    <ModalDialog show={show} onCancel={onCancel}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex-grow">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              Add Comment
            </h3>
            <div className="mt-2">
              <AddCommentForm
                onAddComment={onConfirm}
                onCancel={onCancel}
              ></AddCommentForm>
            </div>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};
