import React from "react";

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  wide?: boolean;
  buttonStyle?: "alert" | "danger" | "neutral" | "default";
}

export const Button: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ onClick, disabled, children, wide, buttonStyle = "default" }) => {
  let buttonClasses = "";

  switch (buttonStyle) {
    case "danger":
      buttonClasses =
        " border border-transparent bg-red-600 text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red";
      break;
    case "alert":
      buttonClasses =
        "text-red-700 border border-transparent bg-red-100 hover:bg-red-50 focus:border-red-300 focus:shadow-outline-red active:bg-red-200";
      break;
    case "neutral":
      buttonClasses =
        "border border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800";
      break;
    default:
      buttonClasses =
        "text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring";
  }

  return (
    <button
      type="button"
      className={`${
        wide ? "w-full " : ""
      }px-4 py-2 text-sm font-bold ${buttonClasses} rounded-md focus:outline-none focus:ring disabled:bg-primary-300 disabled:cursor-not-allowed transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
