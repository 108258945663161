type Maybe<T> = T | null | undefined;
type Edge<NodeType> = {
  node?: Maybe<NodeType>;
};
type QueryResult<NodeType> = Maybe<{
  edges?: Maybe<Maybe<Edge<NodeType>>[]>;
}>;

function removeUndefinedArrayEntries<T>(
  array: (T | undefined | null)[] | null | undefined
): T[] {
  if (array === undefined || array === null) {
    return [];
  }

  return array.filter((element): element is T => {
    return element !== undefined && element !== null;
  });
}

export function nodesFromCollectionQuery<NodeType>(
  queryResult: QueryResult<NodeType>
): NodeType[] {
  return removeUndefinedArrayEntries(
    removeUndefinedArrayEntries(queryResult?.edges).map((edge) => edge.node)
  );
}
