import { gql } from "@apollo/client";
import { faStar as faStarRegular } from "@fortawesome/pro-regular-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { LiveReloadIndicator } from "../../../component/LiveReloadIndicator";
import { Button } from "../../../component/Button";
import { PageSizeSelectorWithFilter } from "../../../component/PageSizeSelectorWithFilter";
import {
  PageVariables,
  PaginationApollo,
} from "../../../component/PaginationApollo";
import { SensorFilter_Order } from "../../../generated/graphql";
import {
  SensorRowFragment,
  useFavoriteSensorMutation,
} from "../../../graphql/Sensor.generated";
import { useConfirmationDialog } from "../../../hook/useConfirmationDialog";
import { useItemSelection } from "../../../hook/useItemSelection";
import { useUrlItemSelectionReader } from "../../../hook/useUrlItemSelection";
import { CommentDialog } from "../component/CommentDialog";
import { SensorCollectionActions } from "../component/SensorCollectionActions";
import { ActiveSensorGroupFiltersList } from "./SensorOverviewScene/ActiveSensorGroupFiltersList";
import { useSensors } from "../hook/useSensors";
import { SensorsQueryVariables } from "../hook/useSensors.generated";
import {
  useDeleteSensorMutation,
  useSetShouldConnectMutation,
} from "./SensorsListScene.generated";
import { SensorsTable } from "./SensorsTable";
import {
  useBooleanUrlState,
  useNumberUrlState,
  useObjectUrlState,
  useUrlState,
} from "../../../hook/useUrlState";
import { SensorDragLayer } from "./component/SensorDragLayer";
import { useToggle } from "../../../hook/useToggle";
import { AddSensorDialog } from "../component/AddSensorDialog";
import { useSensorApi } from "../hook/useSensorApi";
import { UpdateSensorDialog } from "../component/UpdateSensorDialog";
import { ModalDialog } from "../../../component/ModalDialog";

export const SET_SHOULD_CONNECT = gql`
  mutation SetShouldConnect($id: ID!, $shouldConnect: Boolean) {
    updateSensor(input: { id: $id, shouldConnect: $shouldConnect }) {
      sensor {
        id
        shouldConnect
      }
    }
  }
`;

export const DELETE_SENSOR = gql`
  mutation deleteSensor($id: ID!) {
    deleteASensor(input: { id: $id }) {
      sensor {
        id
      }
    }
  }
`;

export const SensorsListScene: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const [poll, togglePoll] = useToggle(true);
  const { selectedItemIds } = useUrlItemSelectionReader({
    queryParameterName: "sensorGroups",
  });

  const [pageVariables, setPageVariables] = useObjectUrlState<PageVariables>(
    "pageInfo",
    {}
  );
  const [filter, setFilter] = useUrlState("search", "");
  const [favoritesOnly, setFavoritesOnly] = useBooleanUrlState(
    "favoritesOnly",
    false
  );
  const [showCommentDialog, setShowCommentDialog] = useState(false);
  const [showAddSensor, setShowAddSensor] = useState(false);
  const [sortFieldValueFromUrl, setSortField] = useUrlState("sortField", "");

  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [sensorsToUpdate, setSensorsToUpdate] = useState(0);
  const [sensorsUpdated, setSensorsUpdated] = useState(0);
  const [updateErrorTexts, setUpdateErrorTexts] = useState<string[]>([]);
  const [downloadErrorTexts, setDownloadErrorTexts] = useState<string[]>([]);

  let sortField: keyof SensorFilter_Order | null = null;
  if (sortFieldValueFromUrl !== "") {
    sortField = sortFieldValueFromUrl as keyof SensorFilter_Order;
  }
  const [sortDirection, setSortDirection] = useUrlState("sortDirection", "asc");
  const [pageSize, setPageSize] = useNumberUrlState("pageSize", 25);
  const queryVariables: SensorsQueryVariables = {
    search: filter,
    favorite: favoritesOnly || null,
    pageSize,
    order: undefined,
  };

  if (sortField) {
    queryVariables.order = {};
    queryVariables.order[sortField] = sortDirection;
  }

  const {
    sensors,
    queryResult: { loading, error, data, fetchMore },
    addCommentToSensors,
    setSensorsShouldConnect,
    setSensorsFavorited,
    deleteSensors,
    disconnectAllSensors,
  } = useSensors({
    variables: {
      ...queryVariables,
      ...pageVariables,
      search: filter,
      approved: true,
      order: sortField
        ? { [sortField]: sortDirection }
        : { deviceName: sortDirection },
      sensorGroups:
        selectedItemIds.length > 0 ? selectedItemIds.join(",") : undefined,
    },
    poll,
  });

  const { downloadImageData, downloadDiagnosticData, triggerUpdate } =
    useSensorApi();

  const {
    items,
    selectedItems: selectedItemsWithToggle,
    selectionCount,
    selectAllItems,
    clearSelection,
  } = useItemSelection({
    items: sensors,
    idSelector: useCallback((sensor: SensorRowFragment) => sensor.id, []),
  });
  const selectedItems = selectedItemsWithToggle.map((item) => item.item);
  const [sensorsSelectedForUpdate, setSensorsSelectedForUpdate] = useState<
    SensorRowFragment[]
  >([]);

  const { showConfirmationDialog: showConfirmationModal } =
    useConfirmationDialog(
      {
        title: "Delete selected sensors",
        message: "Do you really want to delete the selected sensors",
        onConfirm: () => {
          deleteSensors(selectedItems);
        },
      },
      [selectedItems]
    );

  const [setShouldConnect] = useSetShouldConnectMutation({
    refetchQueries: ["Sensors"],
  });
  const [deleteSensor] = useDeleteSensorMutation({
    refetchQueries: ["Sensors"],
  });
  const [favoriteSensor] = useFavoriteSensorMutation({
    refetchQueries: ["Sensors"],
  });

  const filterConnectedSensors = (sensors: SensorRowFragment[]) =>
    sensors.filter((sensor) => sensor.vpnStatus && sensor.type === "sensor");

  const handleDownloadResults = (
    results: PromiseSettledResult<void>[],
    sensors: SensorRowFragment[]
  ) => {
    const errorTexts = [];

    for (let i = 0; i < results.length; i++) {
      // const errors = results[i].errors;
      const result = results[i];
      if (result.status === "rejected") {
        errorTexts.push(
          `Download for sensor ${sensors[i].deviceName} failed: ${result.reason}`
        );
      }
    }

    setDownloadErrorTexts(errorTexts);
  };

  const downloadImageDataForSensors = (sensors: SensorRowFragment[]) => {
    const connectedSensors = filterConnectedSensors(sensors);
    Promise.allSettled(
      connectedSensors.map((sensor) =>
        downloadImageData(sensor, `imageData_${sensor.deviceName}.tar.gz`)
      )
    ).then((result) => handleDownloadResults(result, connectedSensors));
  };

  const downloadDiagnosticDataForSensors = (sensors: SensorRowFragment[]) => {
    const connectedSensors = filterConnectedSensors(sensors);
    Promise.allSettled(
      connectedSensors.map((sensor) =>
        downloadDiagnosticData(
          sensor,
          `diagnostics_${sensor.deviceName}.tar.gz`
        )
      )
    ).then((result) => handleDownloadResults(result, connectedSensors));
  };

  const doTriggerSensorUpdates = (
    sensors: SensorRowFragment[],
    firmwareName: string
  ) => {
    const connectedSensors = filterConnectedSensors(sensors);
    setSensorsUpdated(0);
    setSensorsToUpdate(connectedSensors.length);
    setUpdateErrorTexts([]);

    Promise.allSettled(
      connectedSensors.map((sensor) => {
        return triggerUpdate(sensor, firmwareName)
          .then((result) => {
            return result;
          })
          .finally(() => {
            setSensorsUpdated(
              (previousSensorsUpdated) => previousSensorsUpdated + 1
            );
          });
      })
    ).then((results) => {
      const errorTexts = [];

      for (let i = 0; i < results.length; i++) {
        // const errors = results[i].errors;
        const result = results[i];
        if (result.status === "rejected") {
          errorTexts.push(
            `Update for sensor ${sensorsSelectedForUpdate[i].deviceName} failed: ${result.reason}`
          );
        }
      }

      setUpdateErrorTexts(errorTexts);
    });
  };

  const showUpdateSensorDialog = (sensors: SensorRowFragment[]) => {
    setSensorsSelectedForUpdate(filterConnectedSensors(sensors));
    setShowUpdateDialog(true);
  };

  if (error) return <p>Error :(</p>;
  return (
    <>
      <div className="flex flex-col max-h-full container mx-auto px-4 sm:px-8">
        <div className="flex-none pt-4 pb-2">
          <div className="flex items-center">
            <h2 className="text-2xl font-semibold leading-tight mr-4">
              Sensors
            </h2>
            <LiveReloadIndicator isPolling={poll} onClick={togglePoll} />
          </div>
          <div className="flex flex-col xl:flex-row xl:items-center justify-between">
            <div className="flex-auto xl:mr-8 flex flex-col xl:items-center xl:flex-row">
              <div className="flex-auto xl:max-w-lg">
                <SensorDragLayer selectionCount={selectionCount} />
                <PageSizeSelectorWithFilter
                  filter={filter}
                  onFilterChange={setFilter}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
              </div>
              <label
                htmlFor="filterFavorites"
                className="xl:pl-4 inline-flex items-center cursor-pointer text-sunglow-500 hover:text-sunglow-600"
                onClick={() => {
                  setFavoritesOnly(!favoritesOnly);
                }}
              >
                <FontAwesomeIcon
                  className="mr-1"
                  icon={favoritesOnly ? faStar : faStarRegular}
                />
                <span className="pt-1 text-gray-800">Favorites only</span>
              </label>
            </div>
            <div className="flex flex-row items-center justify-between pt-2 xl:pt-0">
              <div className="my-1 flex">
                <Button onClick={() => disconnectAllSensors()}>
                  Disconnect all
                </Button>
                <div className="ml-5">
                  <Button
                    onClick={() => {
                      setShowAddSensor(true);
                    }}
                  >
                    Add sensor
                  </Button>
                </div>
              </div>
              {selectionCount > 0 ? (
                <div className="ml-5">
                  <SensorCollectionActions
                    selectionCount={selectionCount}
                    onEnableVpn={() => {
                      setSensorsShouldConnect(selectedItems, true);
                    }}
                    onDisableVpn={() => {
                      setSensorsShouldConnect(selectedItems, false);
                    }}
                    onComment={() => {
                      setShowCommentDialog(true);
                    }}
                    onSetSensorsFavorited={() => {
                      setSensorsFavorited(selectedItems, true);
                    }}
                    onSetSensorsUnfavorited={() => {
                      setSensorsFavorited(selectedItems, false);
                    }}
                    onDelete={() => {
                      showConfirmationModal();
                    }}
                    onDownloadImageData={() => {
                      downloadImageDataForSensors(selectedItems);
                    }}
                    onDownloadDiagnosticData={() => {
                      downloadDiagnosticDataForSensors(selectedItems);
                    }}
                    onTriggerUpdate={() => {
                      showUpdateSensorDialog(selectedItems);
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <ActiveSensorGroupFiltersList />
        <SensorsTable
          items={items}
          itemSelectionCount={selectionCount}
          selectedItemIds={selectedItems.map((selectedItem) => selectedItem.id)}
          selectAllItems={selectAllItems}
          clearItemSelection={clearSelection}
          loading={loading}
          onSetShouldConnect={(id, shouldConnect) => {
            setShouldConnect({ variables: { id, shouldConnect } });
          }}
          onDeleteSensor={(id) => {
            deleteSensor({ variables: { id } });
          }}
          onFavoriteSensor={(id, favorite) => {
            favoriteSensor({ variables: { id, favorite } });
          }}
          onDownloadImageData={(sensor) => {
            downloadImageDataForSensors([sensor]);
          }}
          onDownloadDiagnosticData={(sensor) => {
            downloadDiagnosticDataForSensors([sensor]);
          }}
          onTriggerUpdate={(sensor) => {
            showUpdateSensorDialog([sensor]);
          }}
          onSortChanged={(field, direction) => {
            setSortField(field);
            setSortDirection(direction);
          }}
          footer={
            <div className="w-full h-12 px-4">
              <PaginationApollo
                pageInfo={data?.sensors?.pageInfo}
                fetchMore={fetchMore}
                onPageChange={setPageVariables}
              >
                <span className="text-xs xs:text-sm text-gray-900 float-right py-5 pr-4">
                  Total of {data?.sensors?.totalCount.toLocaleString()} sensors
                </span>
              </PaginationApollo>
            </div>
          }
        />
      </div>
      <CommentDialog
        show={showCommentDialog}
        onConfirm={(comment) => {
          setShowCommentDialog(false);
          addCommentToSensors(selectedItems, comment);
        }}
        onCancel={() => {
          setShowCommentDialog(false);
        }}
      />
      <AddSensorDialog
        show={showAddSensor}
        onClose={() => setShowAddSensor(false)}
      />
      <UpdateSensorDialog
        show={showUpdateDialog}
        sensors={sensorsSelectedForUpdate}
        onConfirm={(firmwareName) => {
          doTriggerSensorUpdates(sensorsSelectedForUpdate, firmwareName);
          setShowUpdateDialog(false);
        }}
        onCancel={() => {
          setShowUpdateDialog(false);
        }}
      />
      <ModalDialog show={sensorsUpdated < sensorsToUpdate} onCancel={() => {}}>
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          {sensorsSelectedForUpdate.length > 1
            ? `Updating ${sensorsSelectedForUpdate.length} sensors…`
            : sensorsSelectedForUpdate.length > 0 &&
              `Updating sensor ${sensorsSelectedForUpdate[0].deviceName}…`}
        </div>
      </ModalDialog>
      <ModalDialog
        show={updateErrorTexts.length > 0}
        onCancel={() => {
          setUpdateErrorTexts([]);
        }}
      >
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex-grow">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              {sensorsSelectedForUpdate.length > 1
                ? `Error updating sensors`
                : `Error updating sensor`}
            </h3>
            <div className="mt-2">
              <ul>
                {updateErrorTexts.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            </div>
            <div className="my-5 flex justify-end">
              <span className="ml-3 inline-flex rounded-md shadow-sm">
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    setUpdateErrorTexts([]);
                  }}
                >
                  Close
                </Button>
              </span>
            </div>
          </div>
        </div>
      </ModalDialog>
      <ModalDialog
        show={downloadErrorTexts.length > 0}
        onCancel={() => {
          setDownloadErrorTexts([]);
        }}
      >
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex-grow">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              {sensorsSelectedForUpdate.length > 1
                ? `Error downloading data from sensors`
                : `Error downloading data from sensor`}
            </h3>
            <div className="mt-2">
              <ul>
                {downloadErrorTexts.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            </div>
            <div className="my-5 flex justify-end">
              <span className="ml-3 inline-flex rounded-md shadow-sm">
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    setDownloadErrorTexts([]);
                  }}
                >
                  Close
                </Button>
              </span>
            </div>
          </div>
        </div>
      </ModalDialog>
    </>
  );
};
