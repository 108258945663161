import React from "react";
import { Routes } from "react-router";
import { Route } from "react-router-dom";
import { SensorDetailScene } from "./SensorDetails/SensorDetailScene";
import SensorOverviewScene from "./SensorOverview/SensorOverviewScene";
import { SensorApprovalScene } from "./SensorApproval/SensorApprovalScene";

interface Props {}

export const SensorScene: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = () => {
  return (
    <Routes>
      <Route path="/approval" element={<SensorApprovalScene />} />
      <Route path="/:sensorId" element={<SensorDetailScene />} />
      <Route path="/" element={<SensorOverviewScene />} />
    </Routes>
  );
};
