import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SensorRowFragment = { __typename?: 'Sensor', id: string, deviceName: string, connected: boolean, shouldConnect: boolean, type: string, httpUrl?: string | null, httpsUrl?: string | null, sshUrl?: string | null, favorite: boolean, connectionStatus: string, updateScheduled: boolean, updateDeadline?: string | null, updateName?: string | null, lastConnection?: { __typename?: 'ConnectionLogEntry', timestamp: string } | null, vpnStatus?: { __typename?: 'SensorVpnStatus', publicAddress: string, vpnAddress: string, connectedSince: string } | null, status?: { __typename?: 'SensorStatus', macAddress?: string | null, firmwareVersion?: string | null, modelType?: string | null } | null };

export type SensorDetailFragment = { __typename?: 'Sensor', id: string, deviceName: string, shouldConnect: boolean, type: string, shouldConnectChanged: string, createdAt: string, connected: boolean, connectionStatus: string, certificateValidUntil: string, favorite: boolean, updateScheduled: boolean, updateDeadline?: string | null, updateName?: string | null, lastConnection?: { __typename?: 'ConnectionLogEntry', timestamp: string } | null, vpnStatus?: { __typename?: 'SensorVpnStatus', publicAddress: string, vpnAddress: string, connectedSince: string } | null, status?: { __typename?: 'SensorStatus', macAddress?: string | null, firmwareVersion?: string | null, hardwareType?: string | null, modelType?: string | null, licenses?: any | null, hmiConnection?: { __typename?: 'SensorHmiConnection', protocol: string, port: string } | null, timezone?: { __typename?: 'SensorTimeZone', name: string, utcOffset: string } | null } | null, sensorGroup?: { __typename?: 'SensorGroup', id: string, name: string } | null };

export type SensorCommentDataFragment = { __typename?: 'SensorComment', id: string, text: string, timestamp?: string | null, user?: { __typename?: 'User', id: string, username: string } | null };

export type SensorDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SensorDetailQuery = { __typename?: 'Query', sensor?: { __typename?: 'Sensor', id: string, deviceName: string, shouldConnect: boolean, type: string, shouldConnectChanged: string, createdAt: string, connected: boolean, connectionStatus: string, certificateValidUntil: string, favorite: boolean, updateScheduled: boolean, updateDeadline?: string | null, updateName?: string | null, lastConnection?: { __typename?: 'ConnectionLogEntry', timestamp: string } | null, vpnStatus?: { __typename?: 'SensorVpnStatus', publicAddress: string, vpnAddress: string, connectedSince: string } | null, status?: { __typename?: 'SensorStatus', macAddress?: string | null, firmwareVersion?: string | null, hardwareType?: string | null, modelType?: string | null, licenses?: any | null, hmiConnection?: { __typename?: 'SensorHmiConnection', protocol: string, port: string } | null, timezone?: { __typename?: 'SensorTimeZone', name: string, utcOffset: string } | null } | null, sensorGroup?: { __typename?: 'SensorGroup', id: string, name: string } | null } | null };

export type SensorCommentsQueryVariables = Types.Exact<{
  deviceName?: Types.InputMaybe<Types.Scalars['String']>;
  pageSize: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SensorCommentsQuery = { __typename?: 'Query', sensorComments?: { __typename?: 'SensorCommentCursorConnection', totalCount: number, pageInfo: { __typename?: 'SensorCommentPageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'SensorCommentEdge', node?: { __typename?: 'SensorComment', id: string, text: string, timestamp?: string | null, user?: { __typename?: 'User', id: string, username: string } | null } | null } | null> | null } | null };

export type FavoriteSensorMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  favorite: Types.Scalars['Boolean'];
}>;


export type FavoriteSensorMutation = { __typename?: 'Mutation', favoriteSensor?: { __typename?: 'favoriteSensorPayload', sensor?: { __typename?: 'Sensor', id: string, favorite: boolean } | null } | null };

export const SensorRowFragmentDoc = gql`
    fragment SensorRow on Sensor {
  id
  lastConnection {
    timestamp
  }
  deviceName
  connected
  shouldConnect
  type
  httpUrl
  httpsUrl
  sshUrl
  favorite
  connectionStatus
  updateScheduled
  updateDeadline
  updateName
  vpnStatus {
    publicAddress
    vpnAddress
    connectedSince
  }
  status {
    macAddress
    firmwareVersion
    modelType
  }
}
    `;
export const SensorDetailFragmentDoc = gql`
    fragment SensorDetail on Sensor {
  id
  lastConnection {
    timestamp
  }
  deviceName
  shouldConnect
  type
  shouldConnectChanged
  createdAt
  connected
  connectionStatus
  certificateValidUntil
  favorite
  updateScheduled
  updateDeadline
  updateName
  vpnStatus {
    publicAddress
    vpnAddress
    connectedSince
  }
  status {
    macAddress
    firmwareVersion
    hardwareType
    modelType
    licenses
    hmiConnection {
      protocol
      port
    }
    timezone {
      name
      utcOffset
    }
  }
  sensorGroup {
    id
    name
  }
}
    `;
export const SensorCommentDataFragmentDoc = gql`
    fragment SensorCommentData on SensorComment {
  id
  text
  timestamp
  user {
    id
    username
  }
}
    `;
export const SensorDetailDocument = gql`
    query SensorDetail($id: ID!) {
  sensor(id: $id) {
    ...SensorDetail
  }
}
    ${SensorDetailFragmentDoc}`;

/**
 * __useSensorDetailQuery__
 *
 * To run a query within a React component, call `useSensorDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSensorDetailQuery(baseOptions: Apollo.QueryHookOptions<SensorDetailQuery, SensorDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorDetailQuery, SensorDetailQueryVariables>(SensorDetailDocument, options);
      }
export function useSensorDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorDetailQuery, SensorDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorDetailQuery, SensorDetailQueryVariables>(SensorDetailDocument, options);
        }
export type SensorDetailQueryHookResult = ReturnType<typeof useSensorDetailQuery>;
export type SensorDetailLazyQueryHookResult = ReturnType<typeof useSensorDetailLazyQuery>;
export type SensorDetailQueryResult = Apollo.QueryResult<SensorDetailQuery, SensorDetailQueryVariables>;
export const SensorCommentsDocument = gql`
    query SensorComments($deviceName: String, $pageSize: Int!, $after: String, $before: String) {
  sensorComments(
    sensor_deviceName: $deviceName
    first: $pageSize
    after: $after
    before: $before
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        ...SensorCommentData
      }
    }
  }
}
    ${SensorCommentDataFragmentDoc}`;

/**
 * __useSensorCommentsQuery__
 *
 * To run a query within a React component, call `useSensorCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorCommentsQuery({
 *   variables: {
 *      deviceName: // value for 'deviceName'
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSensorCommentsQuery(baseOptions: Apollo.QueryHookOptions<SensorCommentsQuery, SensorCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorCommentsQuery, SensorCommentsQueryVariables>(SensorCommentsDocument, options);
      }
export function useSensorCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorCommentsQuery, SensorCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorCommentsQuery, SensorCommentsQueryVariables>(SensorCommentsDocument, options);
        }
export type SensorCommentsQueryHookResult = ReturnType<typeof useSensorCommentsQuery>;
export type SensorCommentsLazyQueryHookResult = ReturnType<typeof useSensorCommentsLazyQuery>;
export type SensorCommentsQueryResult = Apollo.QueryResult<SensorCommentsQuery, SensorCommentsQueryVariables>;
export const FavoriteSensorDocument = gql`
    mutation favoriteSensor($id: ID!, $favorite: Boolean!) {
  favoriteSensor(input: {id: $id, favorite: $favorite}) {
    sensor {
      id
      favorite
    }
  }
}
    `;
export type FavoriteSensorMutationFn = Apollo.MutationFunction<FavoriteSensorMutation, FavoriteSensorMutationVariables>;

/**
 * __useFavoriteSensorMutation__
 *
 * To run a mutation, you first call `useFavoriteSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteSensorMutation, { data, loading, error }] = useFavoriteSensorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      favorite: // value for 'favorite'
 *   },
 * });
 */
export function useFavoriteSensorMutation(baseOptions?: Apollo.MutationHookOptions<FavoriteSensorMutation, FavoriteSensorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FavoriteSensorMutation, FavoriteSensorMutationVariables>(FavoriteSensorDocument, options);
      }
export type FavoriteSensorMutationHookResult = ReturnType<typeof useFavoriteSensorMutation>;
export type FavoriteSensorMutationResult = Apollo.MutationResult<FavoriteSensorMutation>;
export type FavoriteSensorMutationOptions = Apollo.BaseMutationOptions<FavoriteSensorMutation, FavoriteSensorMutationVariables>;