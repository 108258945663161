import React, { useState } from "react";
import { ModalDialog } from "../../../component/ModalDialog";
import { SensorFirmwareSelector } from "../../../component/SensorFirmwareSelector";
import { Button } from "../../../component/Button";

interface UpdateSensorData {
  deviceName: string;
}

interface Props {
  show: boolean;
  sensors: UpdateSensorData[];
  onConfirm: (firmwareName: string) => void;
  onCancel: () => void;
}

export const UpdateSensorDialog: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ show, sensors, onConfirm, onCancel }) => {
  const [firmwareName, setFirmwareName] = useState<string | null>(null);

  return (
    <ModalDialog show={show} onCancel={onCancel}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div>
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex-grow">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              {sensors.length > 1
                ? `Update ${sensors.length} sensors`
                : sensors.length > 0 &&
                  `Update sensor ${sensors[0].deviceName}`}
            </h3>
          </div>
          <div className="mt-2">
            <form>
              <SensorFirmwareSelector
                firmwareName={firmwareName}
                onChangeFirmwareName={setFirmwareName}
              />
            </form>
            <div className="mt-5 flex justify-end">
              <span className="inline-flex rounded-md shadow-sm">
                <Button
                  buttonStyle="neutral"
                  onClick={(event) => {
                    event.preventDefault();
                    onCancel();
                  }}
                >
                  Cancel
                </Button>
              </span>
              <span className="ml-3 inline-flex rounded-md shadow-sm">
                <Button
                  disabled={firmwareName === null}
                  onClick={(event) => {
                    event.preventDefault();
                    if (firmwareName !== null) {
                      onConfirm(firmwareName);
                    }
                    setFirmwareName(null);
                  }}
                >
                  Update
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};
