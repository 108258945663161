import React from "react";
import dayjs from "dayjs";

interface Props {
  deadline: string | null | undefined;
}

export const ScheduledUpdateIndicator: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ deadline }) => {
  return (
    <span
      className="text-2xl font-medium text-gray-800 px-1 align-middle"
      title={`Update scheduled, deadline ${
        deadline ? dayjs(deadline).fromNow() : "unavailable"
      }.`}
    >
      <i className="icon-firmware_update" />
    </span>
  );
};
