import React from "react";
import { Routes, Route } from "react-router";
import { UserListScene } from "./UserListScene";
import { UserDetailsScene } from "./UserDetailsScene";
import { UserAddScene } from "./UserAddScene";

interface Props {}

export const UserManagementScene: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = (props: Props) => {
  return (
    <Routes>
      <Route path="/add" element={<UserAddScene />} />
      <Route path="/:userId" element={<UserDetailsScene />} />
      <Route path="/" element={<UserListScene />} />
    </Routes>
  );
};
