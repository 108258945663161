import streamSaver from "streamsaver";
import { WritableStream } from "web-streams-polyfill/ponyfill";

streamSaver.WritableStream = WritableStream;
streamSaver.mitm = "/streamSaver/mitm.html?version=2.0.0";

export class UrlDownloadStream {
  private readonly url: string;
  private readonly accessToken?: string;

  constructor(url: string, accessToken?: string) {
    this.url = url;
    this.accessToken = accessToken;
  }

  public async download(downloadFilename: string) {
    const headers = new Headers();

    if (this.accessToken) {
      headers.append("Authorization", "Bearer " + this.accessToken);
    }

    const response = await fetch(this.url, { headers, credentials: "include" });

    if (!response.ok) {
      throw new Error(
        `Response contained an error: ${response.status} ${response.statusText}`
      );
    }

    const responseContentStream = response.body;

    if (!responseContentStream) {
      throw new Error("Response did not provide a streamable body");
    }

    const fileStream = streamSaver.createWriteStream(downloadFilename);
    const writer = fileStream.getWriter();
    const reader = responseContentStream.getReader();

    const handleNextChunk = async (): Promise<any> => {
      const dataChunk = await reader.read();

      if (dataChunk.done) {
        return writer.close();
      }

      return writer.write(dataChunk.value).then(handleNextChunk);
    };

    handleNextChunk();
  }
}
