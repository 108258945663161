import React, { useState } from "react";
import { Button } from "../../../component/Button";

interface Props {
  onAddComment: (comment: string) => void;
  onCancel: () => void;
}

export const AddCommentForm: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ onAddComment, onCancel }) => {
  const [commentText, setCommentText] = useState("");

  return (
    <form>
      <div className="flex rounded-md shadow-sm mt-1 sm:mt-0 sm:pt-5">
        <textarea
          id="about"
          placeholder="Your comment..."
          rows={3}
          className="border border-gray-200 p-4 form-textarea block w-full sm:text-sm sm:leading-5"
          value={commentText}
          onChange={(event) => setCommentText(event.target.value)}
        ></textarea>
      </div>
      <div className="my-5 flex justify-end">
        <span className="inline-flex rounded-md shadow-sm">
          <Button
            buttonStyle="neutral"
            onClick={(event) => {
              event.preventDefault();
              onCancel();
            }}
          >
            Cancel
          </Button>
        </span>
        <span className="ml-3 inline-flex rounded-md shadow-sm">
          <Button
            onClick={(event) => {
              event.preventDefault();
              onAddComment(commentText);
              setCommentText("");
            }}
          >
            Save
          </Button>
        </span>
      </div>
    </form>
  );
};
