import { useUrlItemSelection } from "../../../hook/useUrlItemSelection";
import { SensorGroupNodeFragment } from "../../../graphql/SensorGroup.generated";
import { useSensorGroups } from "./useSensorGroups";

export const useSensorGroupsWithSelection = () => {
  const { sensorGroups } = useSensorGroups();
  const urlItemSelection = useUrlItemSelection({
    items: sensorGroups,
    localStorageKey: "selectedSensorGroups",
    idSelector: (sensorGroupNode: SensorGroupNodeFragment) =>
      sensorGroupNode.id,
    queryParameterName: "sensorGroups",
  });

  return {
    selection: urlItemSelection,
    sensorGroups,
  };
};
