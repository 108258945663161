import React, { FunctionComponent } from "react";
import { ReactComponent as Logo } from "./../images/HAGL_Logo_180611_RGB_negativ_clean.svg";
import { NavLink } from "./Navigation/NavLink";

interface Props {
  menuItems: {
    title: string;
    path: string;
    highlight?: boolean;
    hidden?: boolean;
  }[];
}

export const Navigation: FunctionComponent<React.PropsWithChildren<Props>> = ({
  menuItems,
  children,
}) => {
  return (
    <nav className="flex items-center justify-between flex-wrap bg-primary-500 p-4">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <Logo width="69" height="37" />
        <span className="font-semibold text-xl tracking-tight pl-4">
          APS Device Management
        </span>
      </div>
      <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden">
        <div className="text-sm lg:flex-grow">
          {menuItems
            .filter((menuItem) => !menuItem.hidden)
            .map((menuItem) => (
              <NavLink
                key={menuItem.title}
                to={menuItem.path}
                highlight={menuItem.highlight}
              >
                {menuItem.title}
              </NavLink>
            ))}
        </div>
      </div>
      {children}
    </nav>
  );
};
