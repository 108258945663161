import { Item } from "../../../../../hook/useItemSelection";
import { SensorGroupNodeFragment } from "../../../../../graphql/SensorGroup.generated";
import { Tree } from "./Tree";
import { TreeNode } from "./TreeNode";

export const nodeListToTree = (
  sensorGroupNodes: Item<SensorGroupNodeFragment>[]
): Tree<Item<SensorGroupNodeFragment>> => {
  const tree: Tree<Item<SensorGroupNodeFragment>> = {
    root: {
      id: "root",
      name: "",
      children: [],
      item: null,
      isExpanded: true,
    },
    nodeList: new Map<string, TreeNode<Item<SensorGroupNodeFragment>>>(),
  };

  sensorGroupNodes = sensorGroupNodes.sort((a, b) =>
    a.item.name.localeCompare(b.item.name)
  );

  sensorGroupNodes.forEach((sensorGroupNode: Item<SensorGroupNodeFragment>) => {
    let node = tree.nodeList.get(sensorGroupNode.item.id);

    if (node) {
      // Insert name into preliminarily created parents
      node = {
        ...node,
        name: sensorGroupNode.item.name,
        item: {
          ...sensorGroupNode,
        },
      };
    } else {
      node = {
        id: sensorGroupNode.item.id,
        name: sensorGroupNode.item.name,
        children: [],
        item: sensorGroupNode,
        isExpanded: true,
      };

      tree.nodeList.set(sensorGroupNode.item.id, node);
    }

    if (sensorGroupNode.item.parent === null) {
      // Add new top level node
      tree.root.children.push(node);
    } else {
      let parent = tree.nodeList.get(sensorGroupNode.item.parent!.id);

      if (parent) {
        parent.children.push(node);
      } else {
        // Add preliminary parent node
        tree.nodeList.set(sensorGroupNode.item.parent!.id, {
          id: sensorGroupNode.item.parent!.id,
          name: "<unknown>",
          children: [node],
          // @TODO maybe this should be null (or a dummy?), because it's the wrong item.
          // See https://suora.atlassian.net/browse/AGLAIAVPN-192 for more details.
          item: sensorGroupNode,
          isExpanded: true,
        });
      }
    }
  });

  return tree;
};
