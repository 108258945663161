import React, { useEffect } from "react";
import { Row } from "react-table";
import { Item } from "../../../../hook/useItemSelection";
import { SensorRowFragment } from "../../../../graphql/Sensor.generated";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useDrag } from "../../../../hook/dragAndDrop/useDrag";

interface Props {
  row: Row<Item<SensorRowFragment>>;
  onSetShouldConnect: (id: string, shouldConnect: boolean) => void;
  onDeleteSensorClicked: (sensor: SensorRowFragment) => void;
  onDownloadImageData: (sensor: SensorRowFragment) => void;
  onDownloadDiagnosticData: (sensor: SensorRowFragment) => void;
  onTriggerUpdate: (sensor: SensorRowFragment) => void;
  onFavoriteSensorClicked: (
    sensor: SensorRowFragment,
    favorite: boolean
  ) => void;
  selectedSensorIds: string[];
}

export const SensorsTableRow: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  row,
  onDeleteSensorClicked,
  onFavoriteSensorClicked,
  onSetShouldConnect,
  onDownloadImageData,
  onDownloadDiagnosticData,
  onTriggerUpdate,
  selectedSensorIds,
}) => {
  const [, drag, preview] = useDrag({
    type: "SENSORS",
    item: {
      id: row.original.item.id,
      primarySensorDeviceName: row.original.item.deviceName,
      draggedSensorIds: selectedSensorIds.includes(row.original.item.id)
        ? selectedSensorIds
        : [row.original.item.id],
      type: "SENSORS",
    },
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <tr
      ref={drag}
      {...row.getRowProps()}
      className="bg-white group even:bg-gray-100"
    >
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className="px-5 py-1 text-sm group-hover:bg-gray-200"
          >
            {cell.render("Cell", {
              onSetShouldConnect,
              onDeleteSensorClicked,
              onFavoriteSensorClicked,
              onDownloadImageData,
              onDownloadDiagnosticData,
              onTriggerUpdate,
            })}
          </td>
        );
      })}
    </tr>
  );
};
