import React, { useState } from "react";
import { useEnvironment } from "../../core/environment";
import { LoginScreen } from "./component/LoginScreen";
import { TwoFactorLoginScreen } from "./component/TwoFactorLoginScreen";
import { Navigate, Route, Routes, useNavigate } from "react-router";
import {
  Credentials,
  SetupTwoFactorAuthScene,
} from "./SetupTwoFactorAuthScene";

interface Props {
  onSuccessfulLogin: () => void;
}

// From https://tailwindcomponents.com/component/sb-admin-2-login-page
export const LoginScene: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ onSuccessfulLogin }) => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { environment } = useEnvironment();
  const [credentials, setCredentials] = useState<Credentials | null>(null);
  const [trustDevice, setTrustDevice] = useState(false);

  const verifyLogin = async (username: string, password: string) => {
    setErrorMessage(null);
    if (username.trim().length === 0) {
      setErrorMessage("Empty username.");
      return;
    }
    if (password.trim().length === 0) {
      setErrorMessage("Empty password.");
      return;
    }

    const csrfResponse = await fetch(
      environment.baseUrl + "/login_csrf_token",
      {
        credentials: "include",
      }
    );
    const csrfData = await csrfResponse.json();

    const response = await fetch(environment.baseUrl + "/login", {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
        _csrf_token: csrfData.token,
      }),
    });
    if (response.status === 200) {
      onSuccessfulLogin();
      return;
    }
    if (response.status === 401) {
      const responseData = await response.json();
      if (responseData.reason === "second_factor_required") {
        navigate("/login/2fa");
        setErrorMessage("");
      } else if (responseData.reason === "two_factor_auth_setup_required") {
        setCredentials({
          username,
          password,
        });
        navigate("/login/setup_2fa");
        setErrorMessage("");
      } else {
        setErrorMessage("Invalid credential");
      }
      return;
    }

    setErrorMessage("Unexpected login error");
  };
  const verifyToken = async (token: number) => {
    const formData = new FormData();
    formData.append("_auth_code", String(token));
    const response = await fetch(
      `${environment.baseUrl}/2fa_check?${trustDevice ? "_trusted=true" : ""}`,
      {
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      }
    );

    if (response.status === 200) {
      navigate("/");
      onSuccessfulLogin();
      return;
    }
    if (response.status !== 401) {
      setErrorMessage("Unexpected login error");
      return;
    }
    setErrorMessage("Token invalid");
  };

  return (
    <Routes>
      <Route
        path="/login/setup_2fa"
        element={<SetupTwoFactorAuthScene credentials={credentials} />}
      />
      <Route
        path="/login/2fa"
        element={
          <TwoFactorLoginScreen
            errorMessage={errorMessage}
            verifyToken={verifyToken}
            trustDevice={trustDevice}
            setTrustDevice={setTrustDevice}
          />
        }
      />
      <Route
        path="/login"
        element={
          <LoginScreen errorMessage={errorMessage} verifyLogin={verifyLogin} />
        }
      />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};
