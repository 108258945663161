import { useModal } from "react-modal-hook";
import { ConfirmationDialog } from "../component/ConfirmationDialog";
interface ModelConfiguration {
  title: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm: () => void;
}

/**
 * Renders a confirmation dialog.
 * @example
 * const { showConfirmationDialog } = useConfirmationDialog(
 *  {
 *    title: "Delete selected sensors",
 *    message: "Do you really want to delete the selected sensors",
 *    onConfirm: () => {
 *      console.log(filter);
 *      deleteSensors(selectedItems());
 *    },
 *  },
 *  [selectedItems]
 * );
 */
export const useConfirmationDialog = (
  {
    title,
    message,
    confirmButtonText = "Confirm",
    cancelButtonText = "Cancel",
    onConfirm,
  }: ModelConfiguration,
  deps: unknown[]
) => {
  const [showModal, hideModal] = useModal(
    () => (
      <ConfirmationDialog
        show={true}
        title={title}
        message={message}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
        onConfirm={() => {
          onConfirm();
          hideModal();
        }}
        onCancel={() => {
          hideModal();
        }}
      />
    ),
    deps
  );

  return {
    showConfirmationDialog: showModal,
  };
};
