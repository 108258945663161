import React, { useState } from "react";
import { Panel } from "../../../component/Panel";
import { BackupApplicationSettingsFragment } from "../../../graphql/ApplicationSettings.generated";
import { Button } from "../../../component/Button";

interface Props {
  applicationSettings: BackupApplicationSettingsFragment;
  onSettingsSaved: (settings: BackupApplicationSettingsFragment) => void;
}

export const SettingsPanel: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ applicationSettings, onSettingsSaved }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [intervalError, setIntervalError] = useState<boolean>(false);
  const [retentionDaysError, setRetentionDaysError] = useState<boolean>(false);
  const [backupIntervalLength, setBackupIntervalLength] = useState(
    applicationSettings.backupIntervalLength.toString()
  );
  const [backupIntervalUnit, setBackupIntervalUnit] = useState(
    applicationSettings.backupIntervalUnit
  );
  const [backupRetentionCount, setBackupRetentionCount] = useState(
    applicationSettings.backupRetentionCount.toString()
  );

  const verifyInput = () => {
    setErrorMessage(null);
    setIntervalError(false);
    setRetentionDaysError(false);

    const intervalLength = parseInt(backupIntervalLength);
    const retentionDays = parseInt(backupRetentionCount);

    if (isNaN(intervalLength)) {
      setErrorMessage("Invalid interval length");
      setIntervalError(true);

      return false;
    }

    if (isNaN(retentionDays)) {
      setErrorMessage("Invalid retention period");
      setRetentionDaysError(true);

      return false;
    }

    return true;
  };

  const units = ["hours", "days"];

  if (!applicationSettings) {
    return null;
  }

  return (
    <Panel>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
          Backup Settings
        </h3>
        <form>
          <div>
            <div>
              <div className="mt-6">
                <div>
                  <label
                    htmlFor="backup_interval"
                    className="block text-sm leading-5 font-medium text-gray-700"
                  >
                    Create a new backup every
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <div className="relative flex items-stretch flex-grow focus-within:z-10">
                      <input
                        value={backupIntervalLength}
                        id="backup_interval"
                        className={`form-input ${
                          intervalError && "border-red-500"
                        } block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
                        onChange={(event) => {
                          setBackupIntervalLength(event.target.value);
                        }}
                      />
                    </div>
                    <div className="relative -ml-px inline-flex items-center">
                      <select
                        className="h-full py-0 pl-2 pr-1 rounded-r-md border border-gray-300 bg-gray-100 text-gray-800 sm:text-sm sm:leading-5 focus:outline-none"
                        value={backupIntervalUnit}
                        onChange={(event) => {
                          setBackupIntervalUnit(event.target.value);
                        }}
                      >
                        {units.map((unit) => {
                          return (
                            <option key={unit} value={unit}>
                              {unit}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <label
                    htmlFor="retention_days"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Number of backups to keep
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <div className="relative flex items-stretch flex-grow focus-within:z-10">
                      <input
                        id="retention_days"
                        type="number"
                        value={backupRetentionCount}
                        onChange={(event) => {
                          setBackupRetentionCount(event.target.value);
                        }}
                        className={`form-input ${
                          retentionDaysError && "border-red-500"
                        } block w-full rounded-none rounded-md transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!!errorMessage && (
            <div className="mt-2">
              <p className="text-xs italic text-red-500">{errorMessage}</p>
            </div>
          )}
          <div className="mt-5 flex justify-end">
            <Button
              onClick={(event) => {
                event.preventDefault();
                if (verifyInput()) {
                  onSettingsSaved({
                    backupIntervalLength: parseInt(backupIntervalLength),
                    backupIntervalUnit: backupIntervalUnit,
                    backupRetentionCount: parseInt(backupRetentionCount),
                  });
                }
              }}
            >
              Save settings
            </Button>
          </div>
        </form>
      </div>
    </Panel>
  );
};
