import React from "react";
import { ApolloError } from "@apollo/client";

interface Props {
  error: ApolloError | null;
}

export const ApolloErrorList: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ error }) => {
  if (error === null) {
    return null;
  }

  return (
    <React.Fragment>
      {error.graphQLErrors.map(({ message }, index) => (
        <span key={index}>{message}</span>
      ))}
    </React.Fragment>
  );
};
