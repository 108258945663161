import React, { useState } from "react";
import { LoginPanel } from "./LoginPanel";
import { Toggle } from "../../../component/Toggle";

interface Props {
  errorMessage: string | null;
  verifyToken: (token: number) => void;
  trustDevice: boolean;
  setTrustDevice: (trustDevice: boolean) => void;
}

export const TwoFactorLoginScreen: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ verifyToken, errorMessage, trustDevice, setTrustDevice }) => {
  const [token, setToken] = useState("");

  return (
    <LoginPanel>
      <h3 className="text-2xl text-center">2-Step Verification</h3>
      <h6 className="text-l text-center">
        This extra step reassures it’s really you signing in
      </h6>
      <form
        className="px-8 pt-6  mb-4 bg-white rounded"
        onSubmit={(event) => {
          event.preventDefault();
          verifyToken(Number(token));
        }}
      >
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-bold text-gray-700"
            htmlFor="token"
          >
            Token
          </label>
          <input
            className={`w-full px-3 py-2 text-sm leading-tight text-gray-700 border ${
              !!errorMessage && token.length > 0 && "border-red-500"
            } rounded shadow appearance-none focus:outline-none focus:shadow-outline`}
            id="token"
            type="number"
            placeholder="123456"
            value={token}
            onChange={(event) => setToken(event.target.value)}
          />
          {!!errorMessage && (
            <p className="text-xs italic text-red-500">{errorMessage}</p>
          )}
          <label
            className="block mt-2 mb-2 text-sm font-bold text-gray-700"
            htmlFor="token"
          >
            Trust this device
          </label>

          <Toggle state={trustDevice} onChange={setTrustDevice} />
        </div>

        <div className="mb-6 text-center">
          <button
            className="w-full px-4 py-2 font-bold text-white bg-sunglow-500 rounded-full hover:bg-sunglow-600 focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Verify
          </button>
        </div>
      </form>
    </LoginPanel>
  );
};
