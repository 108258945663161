import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SensorFirmwareNamesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SensorFirmwareNamesQuery = { __typename?: 'Query', sensorFirmware?: { __typename?: 'SensorFirmwareCursorConnection', edges?: Array<{ __typename?: 'SensorFirmwareEdge', node?: { __typename?: 'SensorFirmware', name: string } | null } | null> | null } | null };


export const SensorFirmwareNamesDocument = gql`
    query SensorFirmwareNames {
  sensorFirmware {
    edges {
      node {
        name
      }
    }
  }
}
    `;

/**
 * __useSensorFirmwareNamesQuery__
 *
 * To run a query within a React component, call `useSensorFirmwareNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorFirmwareNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorFirmwareNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSensorFirmwareNamesQuery(baseOptions?: Apollo.QueryHookOptions<SensorFirmwareNamesQuery, SensorFirmwareNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorFirmwareNamesQuery, SensorFirmwareNamesQueryVariables>(SensorFirmwareNamesDocument, options);
      }
export function useSensorFirmwareNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorFirmwareNamesQuery, SensorFirmwareNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorFirmwareNamesQuery, SensorFirmwareNamesQueryVariables>(SensorFirmwareNamesDocument, options);
        }
export type SensorFirmwareNamesQueryHookResult = ReturnType<typeof useSensorFirmwareNamesQuery>;
export type SensorFirmwareNamesLazyQueryHookResult = ReturnType<typeof useSensorFirmwareNamesLazyQuery>;
export type SensorFirmwareNamesQueryResult = Apollo.QueryResult<SensorFirmwareNamesQuery, SensorFirmwareNamesQueryVariables>;