import { DropTargetHookSpec, useDrop as useReactDndDrop } from "react-dnd";
import { DragObject } from "./useDrag";
export type DropResult = void;

// Keeping this here for the future.
// type DropTargetHookSpecWithGenericAccept<
//   DragObject,
//   DropResult,
//   CollectedProps,
//   AcceptType
// > = Omit<
//   DropTargetHookSpec<DragObject, DropResult, CollectedProps>,
//   "accept"
// > & { accept: AcceptType };

export const useDrop = <CollectedProps = unknown>(
  spec: DropTargetHookSpec<DragObject, DropResult, CollectedProps>
) => useReactDndDrop<DragObject, DropResult, CollectedProps>(spec);
