import React, { useState } from "react";
import { useAutoFocus } from "../../../../../hook/useAutoFocus";
import { useUpdateSensorGroupNameMutation } from "../../../component/SensorGroups.generated";
import { TreeNode } from "./TreeNode";

interface Props<T> {
  node: TreeNode<T>;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const SensorGroupNameInput = <T extends unknown>({
  node,
  onSuccess = () => {},
  onCancel = () => {},
}: Props<T>) => {
  const [groupName, setGroupName] = useState("");
  const [updateSensorGroup] = useUpdateSensorGroupNameMutation({
    variables: { id: node.id, name: groupName },
    refetchQueries: ["SensorGroups"],
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (groupName.trim() === "") {
      return;
    }
    updateSensorGroup().then();
    setGroupName("");
    onSuccess();
  };

  const inputRef = useAutoFocus();

  return (
    <form className="inline-flex" onSubmit={handleSubmit}>
      <input
        ref={inputRef}
        value={groupName}
        onChange={(event) => setGroupName(event.target.value)}
        onBlur={() => onCancel()}
        onKeyUp={(event) => event.key === "Escape" && onCancel()}
      ></input>
    </form>
  );
};
