import React, { useState } from "react";
import { Button } from "../../../component/Button";
import {
  SearchableSelectInput,
  SelectOption,
} from "../../../component/Form/SearchableSelectInput";
import { useCurrentUserQuery } from "../../../component/AuthenticatedNavigation.generated";

interface Props {
  onUserAdd: (username: string, password: string, roles: string[]) => void;
  errorMessages: string[];
}

export const UserAddPanel: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ onUserAdd, errorMessages }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [roles, setRoles] = useState<SelectOption>({
    value: ["ROLE_USER"],
    label: "User",
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const { data } = useCurrentUserQuery();

  const availableRoles = [
    { value: ["ROLE_USER"], label: "User" },
    { value: ["ROLE_ADMIN", "ROLE_USER"], label: "Admin" },
  ];

  const verifyInput = () => {
    setErrorMessage(null);
    setPasswordError(false);
    setUsernameError(false);

    if (username.trim().length === 0) {
      setErrorMessage("Empty username.");
      setUsernameError(true);
      return false;
    }
    if (password.trim().length === 0) {
      setErrorMessage("Empty password.");
      setPasswordError(true);
      return false;
    }

    return true;
  };

  return (
    <div className="bg-white shadow sm:rounded-lg m-4 w-1/4">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-8">
          Add a new user
        </h3>
        <form>
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Username
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="username"
                type="text"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                required
                className={`appearance-none block w-full px-3 py-2 border ${
                  usernameError && "border-red-500"
                } rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              />
            </div>
          </div>

          {data?.currentUser?.roles.includes("ROLE_SUPER_ADMIN") && (
            <div className="mt-6">
              <label
                htmlFor="role"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Role
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <SearchableSelectInput
                  id="role"
                  value={roles}
                  onChange={(selectedRole) => setRoles(selectedRole)}
                  options={availableRoles}
                />
              </div>
            </div>
          )}

          <div className="mt-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Password
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="password"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
                className={`appearance-none block w-full px-3 py-2 border ${
                  passwordError && "border-red-500"
                } rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              />
            </div>
          </div>

          {!!errorMessage && (
            <p className="text-xs italic text-red-500">{errorMessage}</p>
          )}

          {errorMessages.map((message, index) => (
            <p key={index} className="text-xs italic text-red-500">
              {message}
            </p>
          ))}

          <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
              <Button
                wide={true}
                onClick={(event) => {
                  event.preventDefault();
                  if (verifyInput()) {
                    onUserAdd(username, password, roles.value);
                  }
                }}
              >
                Add User
              </Button>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};
