import React from "react";

interface Props {
  percentage: number;
}

export const ProgressBar: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ percentage }) => {
  return (
    <div className="rounded-full w-full h-6 overflow-hidden relative bg-gray-400">
      <div className="absolute inset flex w-full h-full justify-center align-middle text-white">
        <span>{Math.round(percentage)}%</span>
      </div>
      <div
        className="bg-primary-500 h-full"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};
