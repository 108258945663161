import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type BackupApplicationSettingsFragment = { __typename?: 'ApplicationSettings', backupIntervalLength: number, backupRetentionCount: number, backupIntervalUnit: string };

export const BackupApplicationSettingsFragmentDoc = gql`
    fragment BackupApplicationSettings on ApplicationSettings {
  backupIntervalLength
  backupRetentionCount
  backupIntervalUnit
}
    `;