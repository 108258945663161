import React from "react";

interface Props {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNext: () => void;
  onPrevious: () => void;
}

interface ButtonProps {
  onClick: () => void;
}

const PreviousButton: React.FunctionComponent<
  React.PropsWithChildren<ButtonProps>
> = ({ onClick }) => (
  <div className="w-0 flex-1 flex">
    <button
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
      className="-mt-px border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium text-gray-600 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150"
    >
      <svg
        className="mr-2 mb-1 h-5 w-5 text-gray-600"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
          clipRule="evenodd"
        />
      </svg>
      Previous
    </button>
  </div>
);

const NextButton: React.FunctionComponent<
  React.PropsWithChildren<ButtonProps>
> = ({ onClick }) => (
  <div className="w-0 flex-1 flex justify-end">
    <button
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
      className="-mt-px border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium text-gray-600 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150"
    >
      Next
      <svg
        className="ml-2 mb-1 h-5 w-5 text-gray-600"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  </div>
);

export const Pagination: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ hasPreviousPage, hasNextPage, onNext, onPrevious, children }) => {
  return (
    <div className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
      {hasPreviousPage ? (
        <PreviousButton onClick={onPrevious} />
      ) : (
        <div className="w-0 flex-1 flex" />
      )}
      <div className="mt-4"> {children}</div>
      {hasNextPage ? (
        <NextButton onClick={onNext} />
      ) : (
        <div className="w-0 flex-1 flex" />
      )}
    </div>
  );
};
