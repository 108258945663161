import React, { useEffect, useState } from "react";

import { gql } from "@apollo/client";
import { UserRowFragment } from "../../graphql/User.generated";
import { PaginationApollo } from "../../component/PaginationApollo";
import { PageSizeSelectorWithFilter } from "../../component/PageSizeSelectorWithFilter";
import { nodesFromCollectionQuery } from "../../helper/nodesFromCollectionQuery";
import { UsersTable } from "./UsersTable";
import { useUsersQuery } from "./UserListScene.generated";
import { Link } from "react-router-dom";
import { Button } from "../../component/Button";

export const USERS = gql`
  query Users(
    $pageSize: Int!
    $after: String
    $before: String
    $username: String
  ) {
    users(
      first: $pageSize
      after: $after
      before: $before
      username: $username
    ) @connection(key: "users", filters: ["username", "pageSize"]) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          ...UserRow
        }
      }
    }
  }
`;

export const UserListScene: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const [pageSize, setPageSize] = useState(25);
  const [filter, setFilter] = useState("");
  const { loading, error, data, fetchMore, refetch } = useUsersQuery({
    variables: { pageSize, username: filter },
  });

  useEffect(() => {
    refetch();
  }, [pageSize, refetch, filter]);

  let tableData: UserRowFragment[] = nodesFromCollectionQuery(data?.users);

  if (error) return <p>Error :(</p>;
  return (
    <>
      <div className="flex flex-col max-h-full container mx-auto px-4 sm:px-8">
        <div className="flex-none py-4">
          <div>
            <h2 className="text-2xl font-semibold leading-tight">Users</h2>
          </div>
          <div className="flex justify-between">
            <PageSizeSelectorWithFilter
              pageSize={pageSize}
              filter={filter}
              onFilterChange={setFilter}
              onPageSizeChange={setPageSize}
            />
            <div className="ml-4 mt-2 flex-shrink-0">
              <span className="inline-flex rounded-md shadow-sm">
                <Link to="/users/add">
                  <Button>Add User</Button>
                </Link>
              </span>
            </div>
          </div>
        </div>
        <UsersTable
          users={tableData}
          loading={loading}
          footer={
            <div className="w-full h-12 px-4">
              <PaginationApollo
                pageInfo={data?.users?.pageInfo}
                fetchMore={fetchMore}
              >
                <span className="text-xs xs:text-sm text-gray-900 float-right py-5 pr-4">
                  Total of {data?.users?.totalCount.toLocaleString()} users
                </span>
              </PaginationApollo>
            </div>
          }
        />
      </div>
    </>
  );
};
