import React from "react";
import Select, { Options } from "react-select";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind";

interface SearchProps {
  value: SelectOption | null;
  onChange: (value: SelectOption) => void;
  options: SelectOption[];
  id?: string;
}

export type SelectOption = {
  value: any | null;
  label: string;
};

const IndicatorSeparator = ({ innerProps }: any) => {
  return <span style={{}} {...innerProps} />;
};

const isSingularOption = (
  selectedOption: SelectOption | Options<SelectOption>
): selectedOption is SelectOption => {
  return !Array.isArray(selectedOption);
};

const { theme } = resolveConfig(tailwindConfig);

const customStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      "&:hover": {},
      boxShadow: null,
      borderColor: state.isFocused ? theme.colors.blue[300] : "inherit",
      fontSize: theme.fontSize.sm,
    };
  },
  menu: (provided: any) => {
    return {
      ...provided,
      fontSize: theme.fontSize.sm,
    };
  },
};

export const SearchableSelectInput: React.FunctionComponent<
  React.PropsWithChildren<SearchProps>
> = ({ onChange, value, options, id }) => {
  return (
    <Select
      styles={customStyles}
      id={id}
      components={{ IndicatorSeparator }}
      options={options}
      value={value}
      onChange={(selectedOption) => {
        if (!selectedOption) {
          return;
        }

        // Only support single select for now to keep handlers simple
        if (isSingularOption(selectedOption)) {
          onChange(selectedOption);
        } else {
          selectedOption = selectedOption[0];
          if (selectedOption) {
            onChange(selectedOption);
          }
        }
      }}
    />
  );
};
