import React, { useState } from "react";

import "./generated/main.css";
import { Navigation } from "./component/Navigation";
import { ApolloProvider, makeVar } from "@apollo/client";

import { ApolloClient, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { SensorScene } from "./scenes/Sensor/SensorScene";
import { LoginScene } from "./scenes/Login/LoginScene";
import { AdminScene } from "./scenes/Admin/AdminScene";
import { AuthenticatedNavigation } from "./component/AuthenticatedNavigation";
import { EnvironmentProvider } from "./core/environment";
import { UserManagementScene } from "./scenes/UserManagement/UserManagementScene";
import { ProfileScene } from "./scenes/Profile/ProfileScene";
import { ModalProvider } from "react-modal-hook";
import { DndProvider } from "./component/DndProvider";
import { SensorFirmwareScene } from "./scenes/Sensor/SensorFirmware/SensorFirmwareScene";
import { fetchWithProgress } from "./util/fetchReplacementWithProgress";

import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";

// Activate relative time display capabilities
dayjs.extend(RelativeTime);

let baseUrl = "/control";

const AppContainer: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => (
  <EnvironmentProvider>
    <ModalProvider>
      <DndProvider>
        <BrowserRouter>{children}</BrowserRouter>
      </DndProvider>
    </ModalProvider>
  </EnvironmentProvider>
);

export const expandedStoreGroupNodes = makeVar<string[]>([]);

function App() {
  const [loggedIn, setLoggedIn] = useState(true);

  const logout = async () => {
    await fetch(`${baseUrl}/logout`, {
      credentials: "include",
      method: "POST",
      redirect: "manual",
    });

    window.location.replace("/");
  };

  if (!loggedIn) {
    return (
      <AppContainer>
        <Navigation menuItems={[]} />
        <LoginScene
          onSuccessfulLogin={() => {
            setLoggedIn(true);
          }}
        />
      </AppContainer>
    );
  }
  const authLink = onError(({ graphQLErrors, forward }) => {
    if (!graphQLErrors) {
      return;
    }

    if (
      graphQLErrors.filter((error) => error.message === "Access Denied.")
        .length > 0
    ) {
      setLoggedIn(false);
    }
  });

  const twoFactorAuthLink = onError((args) => {
    const { networkError } = args;
    if (!networkError) {
      return;
    }

    if (!("result" in networkError)) {
      return;
    }

    if (networkError.result.reason === "second_factor_required") {
      setLoggedIn(false);
    }
  });

  const httpLink = createUploadLink({
    credentials: "include",
    uri: baseUrl + "/api/graphql",
    fetch: fetchWithProgress,
  });

  const client = new ApolloClient({
    connectToDevTools: true,
    cache: new InMemoryCache({
      typePolicies: {
        SensorGroup: {
          fields: {
            isExpanded: {
              read(foo, { readField }) {
                const id = readField("id");
                if (typeof id !== "string") {
                  return false;
                }
                return expandedStoreGroupNodes().includes(id);
              },
            },
          },
        },
      },
    }),
    // I assume the typings are not yet correct,
    // because this is how the docs do it, and it's working.
    // @ts-ignore
    link: twoFactorAuthLink.concat(authLink.concat(httpLink)),
  });

  return (
    <AppContainer>
      <ApolloProvider client={client}>
        <AuthenticatedNavigation onLogout={logout} />
        <div className="flex-1 overflow-y-auto">
          <Routes>
            <Route path="/sensor/*" element={<SensorScene />} />
            <Route path="/firmware" element={<SensorFirmwareScene />} />
            <Route path="/users/*" element={<UserManagementScene />} />
            <Route path="/admin" element={<AdminScene />} />
            <Route path="/profile" element={<ProfileScene />} />
            <Route path="*" element={<Navigate to="/sensor" replace />} />
          </Routes>
        </div>
      </ApolloProvider>
    </AppContainer>
  );
}

export default App;
