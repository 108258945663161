import React from "react";
import { Row } from "react-table";
import { SensorFirmware } from "../SensorFirmwareScene";

interface Props {
  row: Row<SensorFirmware>;
  onDownloadFirmware: (firmware: SensorFirmware) => void;
  onDeleteFirmware: (firmware: SensorFirmware) => void;
}

export const SensorFirmwareTableRow: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ row, onDownloadFirmware, onDeleteFirmware }) => {
  return (
    <tr {...row.getRowProps()} className="bg-white even:bg-gray-100">
      {row.cells.map((cell) => {
        return (
          <td {...cell.getCellProps()} className="px-5 py-1 text-sm">
            {cell.render("Cell", {
              downloadFirmware: onDownloadFirmware,
              deleteFirmware: onDeleteFirmware,
            })}
          </td>
        );
      })}
    </tr>
  );
};
