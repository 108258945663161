import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type SensorGroupNodeFragment = { __typename?: 'SensorGroup', id: string, name: string, isExpanded: boolean, parent?: { __typename?: 'SensorGroup', id: string } | null, sensors?: { __typename?: 'SensorCursorConnection', totalCount: number } | null };

export const SensorGroupNodeFragmentDoc = gql`
    fragment SensorGroupNode on SensorGroup {
  id
  name
  parent {
    id
  }
  sensors {
    totalCount
  }
  isExpanded @client
}
    `;