import React from "react";
import { SearchInput } from "./Form/SearchInput";

interface Props {
  filter: string;
  onFilterChange: (filter: string) => void;

  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
}

export const PageSizeSelectorWithFilter: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  filter,
  onFilterChange,
  pageSize,

  onPageSizeChange,
}) => {
  return (
    <div className="my-2 flex flex-row">
      <div className="flex flex-row mb-0">
        <div className="relative">
          <select
            value={pageSize}
            onChange={(event) => onPageSizeChange(Number(event.target.value))}
            className="appearance-none h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="flex-auto">
        <SearchInput onChange={onFilterChange} value={filter} />
      </div>
    </div>
  );
};
