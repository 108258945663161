import { useEffect, useMemo, useState } from "react";

export interface Item<ItemType> {
  item: ItemType;
  toggle: () => void;
  isSelected: boolean;
}

export interface Options<ItemType> {
  items: ItemType[];
  idSelector: (item: ItemType) => string;
}

export interface Result<ItemType> {
  selectedItems: Item<ItemType>[];
  selectionCount: number;
  items: Item<ItemType>[];
  selectAllItems: () => void;
  clearSelection: () => void;
}

export const useItemSelection: <ItemType>(
  options: Options<ItemType>
) => Result<ItemType> = ({ items, idSelector }) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  /*
   * itemIds are being joined here to produce a scalar value. This prevents the
   * useEffect hook from being called on every render with a different items array
   * instance.
   */
  const itemIds = items.map((item) => idSelector(item)).join(":");

  useEffect(() => {
    const ids = itemIds.split(":");
    setSelectedIds((previousSelectedIds) =>
      previousSelectedIds.filter((id) => ids.includes(id))
    );
  }, [itemIds]);

  const itemsWithToggle = useMemo(
    () =>
      items.map((item) => {
        const itemId = idSelector(item);
        return {
          item,
          toggle: () => {
            setSelectedIds((previousSelectedIds) => {
              const index = previousSelectedIds.indexOf(itemId);
              if (index >= 0) {
                return [
                  ...previousSelectedIds.slice(0, index),
                  ...previousSelectedIds.slice(index + 1),
                ];
              }
              return [...previousSelectedIds, itemId];
            });
          },
          isSelected: selectedIds.includes(itemId),
        };
      }),
    [items, idSelector, selectedIds]
  );
  return {
    selectedItems: itemsWithToggle.filter((item) =>
      selectedIds.includes(idSelector(item.item))
    ),
    selectionCount: selectedIds.length,
    items: itemsWithToggle,
    selectAllItems: () => setSelectedIds(items.map((item) => idSelector(item))),
    clearSelection: () => setSelectedIds([]),
  };
};
