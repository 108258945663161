import { gql } from "@apollo/client";
import { SensorGroupNodeFragment } from "../../../graphql/SensorGroup.generated";
import { nodesFromCollectionQuery } from "../../../helper/nodesFromCollectionQuery";
import { useSensorGroupsQuery } from "./useSensorGroups.generated";

export const SENSOR_GROUPS = gql`
  query SensorGroups($limit: Int!) {
    sensorGroups(first: $limit) {
      edges {
        node {
          ...SensorGroupNode
        }
      }
    }
  }
`;

export const useSensorGroups = () => {
  const queryResult = useSensorGroupsQuery({
    pollInterval: 30000,
    variables: { limit: 1000 },
  });

  const sensorGroups: SensorGroupNodeFragment[] = nodesFromCollectionQuery(
    queryResult.data?.sensorGroups
  );

  const incomingGroup = {
    id: "",
    parent: null,
    name: "Incoming",
    isExpanded: false,
  };

  return {
    queryResult,
    sensorGroups: [incomingGroup, ...sensorGroups],
  };
};
