import React from "react";
import { LoginPanel } from "../LoginPanel";

interface Props {
  onNext: () => void;
}

export const Explanation: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ onNext }) => {
  return (
    <LoginPanel>
      <h3 className="text-2xl text-center mb-5">
        Setup Two Factor Authentication
      </h3>
      <div>
        Your administrator configured the APS Device Management to enforce Two
        Factor Authentication as an additional layer of protection.
      </div>
      <div className="pt-2 text-center">
        Please install an authenticator app such as{" "}
        <a
          className="font-bold text-tradewind-500 cursor-pointer hover:text-gray-600"
          href="https://authy.com/download/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Authy
        </a>{" "}
        on your smartphone before you continue.
      </div>

      <div className="mt-10 text-center">
        <button
          className="w-full px-4 py-2 font-bold text-white bg-sunglow-500 rounded-full hover:bg-sunglow-600 focus:outline-none focus:shadow-outline"
          onClick={() => onNext()}
        >
          Start Setup
        </button>
      </div>
    </LoginPanel>
  );
};
