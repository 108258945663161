import React from "react";
import { SensorGroupTree } from "./SensorOverviewScene/SensorGroupTree";
import { useSensorGroupsWithSelection } from "../hook/useSensorGroupsWithSelection";
import { filterGroups } from "./filterSensorGroupItems";

interface Props {
  filter?: string;
}
export const SensorGroupTreeScene: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ filter = "" }) => {
  const {
    selection: { items: sensorGroupItems },
  } = useSensorGroupsWithSelection();

  return (
    <SensorGroupTree
      nodes={filterGroups(sensorGroupItems, filter)}
    ></SensorGroupTree>
  );
};
