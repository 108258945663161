import React from "react";
import { gql } from "@apollo/client";
import { useCreateUserMutation } from "./UserAddScene.generated";
import { UserAddPanel } from "./component/UserAddPanel";
import { useNavigate } from "react-router";

export const ADD_USER = gql`
  mutation createUser(
    $username: String!
    $password: String!
    $roles: Iterable!
  ) {
    createUser(
      input: { username: $username, password: $password, roles: $roles }
    ) {
      user {
        id
      }
    }
  }
`;

export const UserAddScene: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const navigate = useNavigate();

  const [createUser, { error: queryError }] = useCreateUserMutation();
  const addUser = (username: string, password: string, roles: string[]) => {
    createUser({
      variables: { username, password, roles },
    })
      .then(() => {
        navigate("/users");
      })
      .catch(() => {
        // Error will be forwarded to the panel
      });
  };

  return (
    <div className="flex items-center justify-center">
      <UserAddPanel
        onUserAdd={addUser}
        errorMessages={
          queryError
            ? queryError.graphQLErrors.map(({ message }) => message)
            : []
        }
      ></UserAddPanel>
    </div>
  );
};
