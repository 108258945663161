import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TriggerSensorUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  version: Types.Scalars['String'];
}>;


export type TriggerSensorUpdateMutation = { __typename?: 'Mutation', triggerFirmwareUpdateOnSensor?: { __typename?: 'triggerFirmwareUpdateOnSensorPayload', sensor?: { __typename?: 'Sensor', id: string, updateScheduled: boolean, updateDeadline?: string | null, updateName?: string | null } | null } | null };


export const TriggerSensorUpdateDocument = gql`
    mutation triggerSensorUpdate($id: ID!, $version: String!) {
  triggerFirmwareUpdateOnSensor(input: {id: $id, version: $version}) {
    sensor {
      id
      updateScheduled
      updateDeadline
      updateName
    }
  }
}
    `;
export type TriggerSensorUpdateMutationFn = Apollo.MutationFunction<TriggerSensorUpdateMutation, TriggerSensorUpdateMutationVariables>;

/**
 * __useTriggerSensorUpdateMutation__
 *
 * To run a mutation, you first call `useTriggerSensorUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerSensorUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerSensorUpdateMutation, { data, loading, error }] = useTriggerSensorUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useTriggerSensorUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TriggerSensorUpdateMutation, TriggerSensorUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerSensorUpdateMutation, TriggerSensorUpdateMutationVariables>(TriggerSensorUpdateDocument, options);
      }
export type TriggerSensorUpdateMutationHookResult = ReturnType<typeof useTriggerSensorUpdateMutation>;
export type TriggerSensorUpdateMutationResult = Apollo.MutationResult<TriggerSensorUpdateMutation>;
export type TriggerSensorUpdateMutationOptions = Apollo.BaseMutationOptions<TriggerSensorUpdateMutation, TriggerSensorUpdateMutationVariables>;