import React, { ReactNode, useEffect, useRef, useState } from "react";
import { SensorRowFragment } from "../../../graphql/Sensor.generated";
import { CellProps, Column, HeaderProps, useTable } from "react-table";
import { Toggle } from "../../../component/Toggle";
import { SensorStatus } from "../component/SensorStatus";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faBrowser,
  faTerminal,
  faTools,
} from "@fortawesome/pro-regular-svg-icons";
import { ConfirmationDialog } from "../../../component/ConfirmationDialog";
import { ActionButton } from "../../../component/ActionButton";
import { Item } from "../../../hook/useItemSelection";
import {
  faStar,
  faSortUp,
  faSortDown,
  faCloudDownload,
} from "@fortawesome/pro-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/pro-regular-svg-icons";
import { SensorFilter_Order } from "../../../generated/graphql";
import { SensorsTableRow } from "./component/SensorsTableRow";
import { ScheduledUpdateIndicator } from "./component/ScheduledUpdateIndicator";

interface Props {
  items: Item<SensorRowFragment>[];
  loading: boolean;
  itemSelectionCount: number;
  selectedItemIds: string[];
  selectAllItems: () => void;
  clearItemSelection: () => void;
  onSetShouldConnect: (id: string, shouldConnect: boolean) => void;
  onDeleteSensor: (id: string) => void;
  onDownloadImageData: (sensor: SensorRowFragment) => void;
  onDownloadDiagnosticData: (sensor: SensorRowFragment) => void;
  onTriggerUpdate: (sensor: SensorRowFragment) => void;
  onFavoriteSensor: (id: string, favorite: boolean) => void;
  onSortChanged: (
    field: keyof SensorFilter_Order,
    direction: "asc" | "desc"
  ) => void;
  footer?: ReactNode;
}

const columns: Column<Item<SensorRowFragment>>[] = [
  {
    id: "selection",
    Header: (props: HeaderProps<Item<SensorRowFragment>>) => {
      const inputRef = useRef<HTMLInputElement>(null);
      useEffect(() => {
        if (!inputRef.current) {
          return;
        }

        inputRef.current.indeterminate =
          // @ts-ignore
          props.itemSelectionCount > 0 &&
          // @ts-ignore
          props.itemSelectionCount < props.rows.length;
        // @ts-ignore
      }, [props.itemSelectionCount, props.rows.length]);

      return (
        <input
          ref={inputRef}
          type="checkbox"
          className="cursor-pointer"
          // @ts-ignore
          checked={
            props.rows.length > 0 &&
            // @ts-ignore
            props.itemSelectionCount === props.rows.length
          }
          onChange={() => {
            // @ts-ignore
            if (props.itemSelectionCount === props.rows.length) {
              // @ts-ignore
              props.clearItemSelection();
            } else {
              // @ts-ignore
              props.selectAllItems();
            }
          }}
        />
      );
    },
    Cell: (props: CellProps<Item<SensorRowFragment>>) => {
      return (
        <input
          type="checkbox"
          className="cursor-pointer"
          checked={props.row.original.isSelected}
          onChange={() => {
            props.row.original.toggle();
          }}
        />
      );
    },
  },
  {
    id: "Device",
    Header: (props: HeaderProps<Item<SensorRowFragment>>) => {
      return (
        <div
          onClick={(event) => {
            // @ts-ignore
            props.onColumnHeaderClicked("deviceName");
          }}
          className="cursor-pointer"
        >
          <span>Device</span>
          <div className="w-4 ml-1 inline-block">
            {/* @ts-ignore */}
            {props.sortField === "deviceName" && (
              <FontAwesomeIcon
                // @ts-ignore
                icon={props.sortDirection === "asc" ? faSortUp : faSortDown}
                className="ml-1 w-4"
              />
            )}
          </div>
        </div>
      );
    },
    Cell: (props: CellProps<Item<SensorRowFragment>>) => {
      return (
        <Link
          to={`/sensor/${encodeURIComponent(props.row.original.item.id)}`}
          className="select-none text-gray-800 hover:text-gray-600 hover:underline"
        >
          {props.row.original.item.deviceName}
        </Link>
      );
    },
  },
  {
    id: "Status",
    Header: (props: HeaderProps<Item<SensorRowFragment>>) => {
      return (
        <div
          onClick={(event) => {
            // @ts-ignore
            props.onColumnHeaderClicked("connectionStatus");
          }}
          className="cursor-pointer"
        >
          <span>Status</span>
          <div className="w-4 ml-1 inline-block">
            {/* @ts-ignore */}
            {props.sortField === "connectionStatus" && (
              <FontAwesomeIcon
                // @ts-ignore
                icon={props.sortDirection === "asc" ? faSortUp : faSortDown}
              />
            )}
          </div>
        </div>
      );
    },
    accessor: (row) => row.item.connected,
    Cell: ({ row }: CellProps<Item<SensorRowFragment>, boolean>) => {
      return (
        <>
          <SensorStatus sensor={row.original.item} />
          {row.original.item.updateScheduled && (
            <ScheduledUpdateIndicator
              deadline={row.original.item.updateDeadline}
            />
          )}
        </>
      );
    },
  },
  {
    id: "Connect",
    Header: (props: HeaderProps<Item<SensorRowFragment>>) => {
      return (
        <div
          onClick={(event) => {
            // @ts-ignore
            props.onColumnHeaderClicked("shouldConnect");
          }}
          className="cursor-pointer"
        >
          <span>Connect</span>
          <div className="w-4 ml-1 inline-block">
            {/* @ts-ignore */}
            {props.sortField === "shouldConnect" && (
              <FontAwesomeIcon
                // @ts-ignore
                icon={props.sortDirection === "asc" ? faSortUp : faSortDown}
                className="ml-1"
              />
            )}
          </div>
        </div>
      );
    },
    accessor: (row) => row.item.shouldConnect,
    Cell: (props: CellProps<Item<SensorRowFragment>, boolean>) => {
      if (props.row.original.item.type === "control") {
        return null;
      }

      return (
        <div className="flex">
          <Toggle
            state={props.value}
            onChange={(newValue) => {
              // @ts-ignore
              props.onSetShouldConnect(props.row.original.item.id, newValue);
            }}
          />
        </div>
      );
    },
  },
  {
    Header: "VPN Address",
    accessor: (row) => row.item.vpnStatus?.vpnAddress,
  },
  {
    id: "actions",
    Header: "",
    Cell: (props: CellProps<Item<SensorRowFragment>>) => {
      return (
        <div className="text-right">
          <ActionButton>
            <div className="mt-2 w-56 rounded-md shadow-lg">
              <div
                className="rounded-md bg-white shadow-xs"
                role="menu"
                aria-orientation="vertical"
              >
                {props.row.original.item.vpnStatus &&
                  props.row.original.item.type === "sensor" && (
                    <>
                      <a
                        className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={props.row.original.item.httpsUrl!}
                      >
                        <FontAwesomeIcon icon={faBrowser} />
                        <span className="ml-2">HTTPS</span>
                      </a>
                      <a
                        className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={props.row.original.item.httpUrl!}
                      >
                        <FontAwesomeIcon icon={faBrowser} />
                        <span className="ml-2">HTTP</span>
                      </a>
                      <a
                        className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={props.row.original.item.sshUrl!}
                      >
                        <FontAwesomeIcon icon={faTerminal} />
                        <span className="ml-2">SSH</span>
                      </a>
                      <button
                        className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        role="menuitem"
                        onClick={(event) => {
                          // @ts-ignore
                          props.onDownloadImageData(props.row.original.item);
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ width: "20" }}
                          icon={faCloudDownload}
                        />
                        <span className="ml-2">Download Image Data</span>
                      </button>
                      <button
                        className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        role="menuitem"
                        onClick={(event) => {
                          // @ts-ignore
                          props.onDownloadDiagnosticData(
                            props.row.original.item
                          );
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ width: "20" }}
                          icon={faCloudDownload}
                        />
                        <span className="ml-2">Download Diagnostic Data</span>
                      </button>
                      <button
                        className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        role="menuitem"
                        onClick={(event) => {
                          // @ts-ignore
                          props.onTriggerUpdate(props.row.original.item);
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ width: "20" }}
                          icon={faTools}
                        />
                        <span className="ml-2">Update Firmware</span>
                      </button>
                    </>
                  )}
                {!props.row.original.item.favorite && (
                  <button
                    className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                    onClick={(event) => {
                      // @ts-ignore
                      props.onFavoriteSensorClicked(
                        props.row.original.item,
                        true
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      className="text-sunglow-500"
                      icon={faStar}
                    />
                    <span className="ml-2">Favorite</span>
                  </button>
                )}
                {props.row.original.item.favorite && (
                  <button
                    className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                    onClick={(event) => {
                      // @ts-ignore
                      props.onFavoriteSensorClicked(
                        props.row.original.item,
                        false
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      className="text-sunglow-500"
                      icon={faStarRegular}
                    />
                    <span className="ml-2">Unfavorite</span>
                  </button>
                )}
                <button
                  className="block px-4 py-2 text-sm leading-5 text-red-500 w-full text-left hover:bg-red-100 focus:outline-none"
                  role="menuitem"
                  onClick={(event) => {
                    // @ts-ignore
                    props.onDeleteSensorClicked(props.row.original.item);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                  <span className="ml-2">Delete</span>
                </button>
              </div>
            </div>
          </ActionButton>
        </div>
      );
    },
  },
];
export const SensorsTable: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  items,
  itemSelectionCount,
  selectedItemIds,
  selectAllItems,
  clearItemSelection,
  onSetShouldConnect,
  onDeleteSensor,
  onFavoriteSensor,
  onSortChanged,
  onDownloadImageData,
  onDownloadDiagnosticData,
  onTriggerUpdate,
  footer,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: items });

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSensor, setSelectedSensor] =
    useState<SensorRowFragment | null>(null);
  const [modalMessage, setModalMessage] = useState("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortField, setSortField] = useState<string | null>(null);

  const onDeleteSensorClicked = (sensor: SensorRowFragment) => {
    setModalMessage(
      `Do you really want to delete "${sensor.deviceName}"? This cannot be undone.`
    );
    setSelectedSensor(sensor);
    setModalOpen(true);
  };

  const onFavoriteSensorClicked = (
    sensor: SensorRowFragment,
    favorite: boolean
  ) => {
    onFavoriteSensor(sensor.id, favorite);
  };

  const onColumnHeaderClicked = (field: keyof SensorFilter_Order) => {
    const direction = sortDirection === "asc" ? "desc" : "asc";

    setSortDirection(direction);
    setSortField(field);

    onSortChanged(field, direction);
  };

  return (
    <React.Fragment>
      <div className="flex -mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto overflow-y-hidden">
        <div className="flex flex-col flex-grow min-w-full shadow rounded-lg overflow-hidden">
          <div className="flex-grow overflow-y-auto">
            <table {...getTableProps()} className="min-w-full leading-normal">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="sticky top-0 z-20 px-5 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider"
                      >
                        {column.render("Header", {
                          itemSelectionCount,
                          selectAllItems,
                          clearItemSelection,
                          onColumnHeaderClicked,
                          sortField,
                          sortDirection,
                        })}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <SensorsTableRow
                      key={row.original.item.id}
                      row={row}
                      onSetShouldConnect={onSetShouldConnect}
                      onDeleteSensorClicked={onDeleteSensorClicked}
                      onFavoriteSensorClicked={onFavoriteSensorClicked}
                      onDownloadImageData={onDownloadImageData}
                      onDownloadDiagnosticData={onDownloadDiagnosticData}
                      onTriggerUpdate={onTriggerUpdate}
                      selectedSensorIds={selectedItemIds}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex-none flex mb-4">{footer && <>{footer}</>}</div>
        </div>
      </div>
      <ConfirmationDialog
        show={modalOpen}
        title={"Delete Sensor"}
        message={modalMessage}
        confirmButtonText={"Delete"}
        cancelButtonText={"Cancel"}
        onConfirm={() => {
          setModalOpen(false);
          onDeleteSensor(selectedSensor!.id);
          setSelectedSensor(null);
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
      ></ConfirmationDialog>
    </React.Fragment>
  );
};
