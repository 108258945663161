import React, { FunctionComponent, useState } from "react";
import { Popover } from "react-tiny-popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";

export const ActionButton: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      isOpen={isOpen}
      positions={["bottom", "top"]}
      align="end"
      padding={0}
      containerStyle={{ overflow: "visible", zIndex: "25" }}
      content={
        <div
          onClick={(event) => {
            event.stopPropagation();
            setIsOpen(false);
          }}
        >
          {children}
        </div>
      }
      onClickOutside={() => setIsOpen(false)}
    >
      <span
        className="cursor-pointer text-gray-800 hover:text-gray-600 px-2"
        title="Actions"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <FontAwesomeIcon icon={faEllipsisV} fixedWidth />
      </span>
    </Popover>
  );
};
