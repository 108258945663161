import React, { useState } from "react";
import { SensorDetailFragment } from "../../../graphql/Sensor.generated";
import { Button } from "../../../component/Button";
import { useSensorApi } from "../hook/useSensorApi";
import { UpdateSensorDialog } from "../component/UpdateSensorDialog";
import { ModalDialog } from "../../../component/ModalDialog";
import dayjs from "dayjs";

interface Props {
  sensor: SensorDetailFragment;
}

export const SensorOperations: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ sensor }) => {
  const {
    downloadImageData,
    downloadDiagnosticData,
    downloadError,
    triggerUpdate,
    updateError,
    updateLoading,
  } = useSensorApi();
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [showUpdateErrorDialog, setShowUpdateErrorDialog] =
    useState<boolean>(true);
  const [showDownloadErrorDialog, setShowDownloadErrorDialog] =
    useState<boolean>(true);

  return (
    <>
      <div className="flex px-4 py-5 flex-col">
        <h3 className="text-lg mb-5 leading-6 font-medium text-gray-900">
          Maintenance Operations
        </h3>
        <div className="mb-2 py-5 border-t border-gray-200">
          <h4>Firmware Updates</h4>
          <p className="mt-1 max-w-2xl leading-5 text-gray-500">
            {sensor.updateScheduled
              ? `Sensor update ${
                  sensor.updateName
                    ? 'to version "' + sensor.updateName + '"'
                    : ""
                } pending - deadline ${
                  sensor.updateDeadline
                    ? dayjs(sensor.updateDeadline).fromNow()
                    : "unavailable"
                }.`
              : "No updates scheduled."}
          </p>
          <div className="flex justify-end flex-col xl:flex-row">
            <div>
              <Button
                onClick={() => {
                  setShowUpdateDialog(true);
                }}
              >
                Update Sensor
              </Button>
            </div>
          </div>
        </div>
        <div className="pt-5 border-t border-gray-200">
          <h4>Diagnostics</h4>
          <div className="flex justify-end flex-col xl:flex-row">
            <div className="mb-2">
              <Button
                onClick={() => {
                  downloadImageData(sensor, "images.tar.gz").catch(() => {}); // Errors handled separately
                  setShowDownloadErrorDialog(true);
                }}
              >
                Download Image Data
              </Button>
            </div>
            <div className="mb-2 ml-2">
              <Button
                onClick={() => {
                  downloadDiagnosticData(sensor, "diagnostics.tar.gz").catch(
                    () => {}
                  ); // Errors handled separately
                  setShowDownloadErrorDialog(true);
                }}
              >
                Download Diagnostic Data
              </Button>
            </div>
          </div>
        </div>
      </div>
      <UpdateSensorDialog
        show={showUpdateDialog}
        sensors={[sensor]}
        onConfirm={(firmwareName) => {
          triggerUpdate(sensor, firmwareName).catch(() => {}); // Errors handled separately
          setShowUpdateErrorDialog(true);
          setShowUpdateDialog(false);
        }}
        onCancel={() => {
          setShowUpdateDialog(false);
        }}
      />
      <ModalDialog show={updateLoading} onCancel={() => {}}>
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          Updating sensor {sensor.deviceName}…
        </div>
      </ModalDialog>
      <ModalDialog
        show={showUpdateErrorDialog && !!updateError}
        onCancel={() => {
          setShowUpdateErrorDialog(false);
        }}
      >
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex-grow">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              Error updating sensor {sensor.deviceName}
            </h3>
            <div className="mt-2">
              {updateError?.graphQLErrors.map(({ message }) => message)}
            </div>
            <div className="my-5 flex justify-end">
              <span className="ml-3 inline-flex rounded-md shadow-sm">
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    setShowUpdateErrorDialog(false);
                  }}
                >
                  Close
                </Button>
              </span>
            </div>
          </div>
        </div>
      </ModalDialog>
      <ModalDialog
        show={showDownloadErrorDialog && !!downloadError}
        onCancel={() => {
          setShowDownloadErrorDialog(false);
        }}
      >
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex-grow">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              Error downloading data from sensor {sensor.deviceName}
            </h3>
            <div className="mt-2">{downloadError}</div>
            <div className="my-5 flex justify-end">
              <span className="ml-3 inline-flex rounded-md shadow-sm">
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    setShowDownloadErrorDialog(false);
                  }}
                >
                  Close
                </Button>
              </span>
            </div>
          </div>
        </div>
      </ModalDialog>
    </>
  );
};
