import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetShouldConnectMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  shouldConnect?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type SetShouldConnectMutation = { __typename?: 'Mutation', updateSensor?: { __typename?: 'updateSensorPayload', sensor?: { __typename?: 'Sensor', id: string, shouldConnect: boolean } | null } | null };

export type DeleteSensorMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteSensorMutation = { __typename?: 'Mutation', deleteASensor?: { __typename?: 'deleteASensorPayload', sensor?: { __typename?: 'Sensor', id: string } | null } | null };


export const SetShouldConnectDocument = gql`
    mutation SetShouldConnect($id: ID!, $shouldConnect: Boolean) {
  updateSensor(input: {id: $id, shouldConnect: $shouldConnect}) {
    sensor {
      id
      shouldConnect
    }
  }
}
    `;
export type SetShouldConnectMutationFn = Apollo.MutationFunction<SetShouldConnectMutation, SetShouldConnectMutationVariables>;

/**
 * __useSetShouldConnectMutation__
 *
 * To run a mutation, you first call `useSetShouldConnectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShouldConnectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShouldConnectMutation, { data, loading, error }] = useSetShouldConnectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shouldConnect: // value for 'shouldConnect'
 *   },
 * });
 */
export function useSetShouldConnectMutation(baseOptions?: Apollo.MutationHookOptions<SetShouldConnectMutation, SetShouldConnectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetShouldConnectMutation, SetShouldConnectMutationVariables>(SetShouldConnectDocument, options);
      }
export type SetShouldConnectMutationHookResult = ReturnType<typeof useSetShouldConnectMutation>;
export type SetShouldConnectMutationResult = Apollo.MutationResult<SetShouldConnectMutation>;
export type SetShouldConnectMutationOptions = Apollo.BaseMutationOptions<SetShouldConnectMutation, SetShouldConnectMutationVariables>;
export const DeleteSensorDocument = gql`
    mutation deleteSensor($id: ID!) {
  deleteASensor(input: {id: $id}) {
    sensor {
      id
    }
  }
}
    `;
export type DeleteSensorMutationFn = Apollo.MutationFunction<DeleteSensorMutation, DeleteSensorMutationVariables>;

/**
 * __useDeleteSensorMutation__
 *
 * To run a mutation, you first call `useDeleteSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSensorMutation, { data, loading, error }] = useDeleteSensorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSensorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSensorMutation, DeleteSensorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSensorMutation, DeleteSensorMutationVariables>(DeleteSensorDocument, options);
      }
export type DeleteSensorMutationHookResult = ReturnType<typeof useDeleteSensorMutation>;
export type DeleteSensorMutationResult = Apollo.MutationResult<DeleteSensorMutation>;
export type DeleteSensorMutationOptions = Apollo.BaseMutationOptions<DeleteSensorMutation, DeleteSensorMutationVariables>;