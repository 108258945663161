import { UrlDownloadStream } from "../../../util/UrlDownloadStream";
import { useEnvironment } from "../../../core/environment";
import { SensorRowFragment } from "../../../graphql/Sensor.generated";
import { gql } from "@apollo/client/core";
import { useTriggerSensorUpdateMutation } from "./useSensorApi.generated";
import { useState } from "react";

export const TRIGGER_SENSOR_UPDATE = gql`
  mutation triggerSensorUpdate($id: ID!, $version: String!) {
    triggerFirmwareUpdateOnSensor(input: { id: $id, version: $version }) {
      sensor {
        id
        updateScheduled
        updateDeadline
        updateName
      }
    }
  }
`;

export const useSensorApi = () => {
  const { environment } = useEnvironment();
  const apiUrl = `${environment.baseUrl}/api/sensorOperations`;

  const [triggerSensorUpdate, { error: updateError, loading: updateLoading }] =
    useTriggerSensorUpdateMutation();

  const [downloadError, setDownloadError] = useState<string>();

  function handleDownloadError(error: any) {
    if (error instanceof Error) {
      setDownloadError(error.message);
    } else if (typeof error === "string") {
      setDownloadError(error);
    } else {
      setDownloadError("Unknown error");
    }

    throw error;
  }

  const downloadImageData = async (
    sensor: SensorRowFragment,
    downloadFilename: string
  ) => {
    setDownloadError(undefined);
    const stream = new UrlDownloadStream(
      `${apiUrl}/${sensor.deviceName}/downloadImageData`
    );

    try {
      return await stream.download(downloadFilename);
    } catch (error) {
      handleDownloadError(error);
    }
  };

  const downloadDiagnosticData = async (
    sensor: SensorRowFragment,
    downloadFilename: string
  ) => {
    setDownloadError(undefined);
    const stream = new UrlDownloadStream(
      `${apiUrl}/${sensor.deviceName}/downloadDiagnosticData`
    );

    try {
      return await stream.download(downloadFilename);
    } catch (error) {
      handleDownloadError(error);
    }
  };

  const triggerUpdate = async (
    sensor: SensorRowFragment,
    firmwareName: string
  ) => {
    return triggerSensorUpdate({
      variables: {
        id: sensor.id,
        version: firmwareName,
      },
    });
  };

  return {
    downloadImageData,
    downloadDiagnosticData,
    downloadError,
    triggerUpdate,
    updateError,
    updateLoading,
  };
};
