import React from "react";
import { SensorGroupNameInput } from "./SensorGroupNameInput";
import { TreeNode } from "./TreeNode";

interface Props {
  node: TreeNode<unknown>;
  editing?: boolean;
  onEditComplete: () => void;
}

export const EditableSensorGroupName: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ node, editing, onEditComplete }) => {
  if (editing) {
    return (
      <SensorGroupNameInput
        node={node}
        onSuccess={onEditComplete}
        onCancel={onEditComplete}
      />
    );
  }

  return <span className="mr-1 text-sm">{node.name}</span>;
};
