import React from "react";

interface Props {
  overflow?: "hidden" | "auto";
}

export const Panel: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  overflow,
}) => {
  const overflowClass = overflow === "auto" ? "" : "overflow-hidden";

  return (
    <div
      className={`bg-white shadow ${overflowClass} relative sm:rounded-lg m-4`}
    >
      {children}
    </div>
  );
};
