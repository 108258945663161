import React, { ReactNode } from "react";
import { UserRowFragment } from "../../graphql/User.generated";
import { CellProps, Column, useTable } from "react-table";
import { UserRoleBadge } from "./component/UserRoleBadge";
import { Link } from "react-router-dom";
import { ActionButton } from "../../component/ActionButton";

interface Props {
  users: UserRowFragment[];
  loading: boolean;
  footer?: ReactNode;
}

const columns: Column<UserRowFragment>[] = [
  {
    Header: "Username",
    id: "username",
    Cell: (props: CellProps<UserRowFragment>) => {
      return props.row.original.roles.includes("ROLE_SUPER_ADMIN") ? (
        <span>{props.row.original.username}</span>
      ) : (
        <Link
          to={`/users/${encodeURIComponent(props.row.original.id)}`}
          className="text-gray-800 hover:text-gray-600 hover:underline"
        >
          {props.row.original.username}
        </Link>
      );
    },
  },
  {
    Header: "Role",
    accessor: "roles",
    Cell: (props: CellProps<UserRowFragment, boolean>) => {
      return <UserRoleBadge roles={props.row.original.roles}></UserRoleBadge>;
    },
  },
  {
    Header: "",
    id: "actions",
    Cell: (props: CellProps<UserRowFragment, boolean>) => {
      return props.row.original.roles.includes("ROLE_SUPER_ADMIN") ? null : (
        <div className="text-right">
          <ActionButton>
            <div className="mt-2 w-56 rounded-md shadow-lg">
              <div
                className="rounded-md bg-white shadow-xs"
                role="menu"
                aria-orientation="vertical"
              >
                <Link
                  to={`/users/${encodeURIComponent(props.row.original.id)}`}
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                >
                  Details
                </Link>
              </div>
            </div>
          </ActionButton>
        </div>
      );
    },
  },
];
export const UsersTable: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ users, footer }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: users });
  return (
    <div className="flex -mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto overflow-y-hidden">
      <div className="flex flex-col flex-grow min-w-full shadow rounded-lg overflow-hidden">
        <div className="flex-grow overflow-y-auto">
          <table {...getTableProps()} className="min-w-full leading-normal">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className="sticky top-0 z-20 px-5 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="bg-white even:bg-gray-100"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="px-5 py-2 text-sm"
                        >
                          {cell.render("Cell", {})}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex-none flex mb-4">{footer && <>{footer}</>}</div>
      </div>
    </div>
  );
};
