import React from "react";
import { UserRowFragment } from "../../../graphql/User.generated";
import { Panel } from "../../../component/Panel";
import { UserRoleBadge } from "./UserRoleBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  user: UserRowFragment;
}

export const UserInfoPanel: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ user }) => {
  return (
    <Panel>
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {user.username}
          <span className="float-right">
            <UserRoleBadge roles={user.roles} />
          </span>
        </h3>
        <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
          Last login: {new Date().toLocaleString()}
        </p>
        {user.twoFactorAuthEnabled && (
          <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
            <FontAwesomeIcon icon={faLock} className="mr-1" />
            Two Factor Authentication enabled
          </p>
        )}
      </div>
    </Panel>
  );
};
