import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApproveSensorMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  approved?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type ApproveSensorMutation = { __typename?: 'Mutation', updateSensor?: { __typename?: 'updateSensorPayload', sensor?: { __typename?: 'Sensor', id: string, approved: boolean } | null } | null };

export type ApproveAllSensorsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ApproveAllSensorsMutation = { __typename?: 'Mutation', approveAllSensor?: { __typename?: 'approveAllSensorPayload', clientMutationId?: string | null } | null };

export type UnapprovedSensorFragment = { __typename?: 'Sensor', id: string, deviceName: string, vpnStatus?: { __typename?: 'SensorVpnStatus', publicAddress: string } | null, status?: { __typename?: 'SensorStatus', modelType?: string | null, macAddress?: string | null } | null };

export type UnapprovedSensorsQueryVariables = Types.Exact<{
  order?: Types.InputMaybe<Types.SensorFilter_Order>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  pageSize: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UnapprovedSensorsQuery = { __typename?: 'Query', sensors?: { __typename?: 'SensorCursorConnection', totalCount: number, pageInfo: { __typename?: 'SensorPageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'SensorEdge', node?: { __typename?: 'Sensor', id: string, deviceName: string, vpnStatus?: { __typename?: 'SensorVpnStatus', publicAddress: string } | null, status?: { __typename?: 'SensorStatus', modelType?: string | null, macAddress?: string | null } | null } | null } | null> | null } | null };

export const UnapprovedSensorFragmentDoc = gql`
    fragment UnapprovedSensor on Sensor {
  id
  deviceName
  vpnStatus {
    publicAddress
  }
  status {
    modelType
    macAddress
  }
}
    `;
export const ApproveSensorDocument = gql`
    mutation approveSensor($id: ID!, $approved: Boolean) {
  updateSensor(input: {id: $id, approved: $approved}) {
    sensor {
      id
      approved
    }
  }
}
    `;
export type ApproveSensorMutationFn = Apollo.MutationFunction<ApproveSensorMutation, ApproveSensorMutationVariables>;

/**
 * __useApproveSensorMutation__
 *
 * To run a mutation, you first call `useApproveSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSensorMutation, { data, loading, error }] = useApproveSensorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      approved: // value for 'approved'
 *   },
 * });
 */
export function useApproveSensorMutation(baseOptions?: Apollo.MutationHookOptions<ApproveSensorMutation, ApproveSensorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveSensorMutation, ApproveSensorMutationVariables>(ApproveSensorDocument, options);
      }
export type ApproveSensorMutationHookResult = ReturnType<typeof useApproveSensorMutation>;
export type ApproveSensorMutationResult = Apollo.MutationResult<ApproveSensorMutation>;
export type ApproveSensorMutationOptions = Apollo.BaseMutationOptions<ApproveSensorMutation, ApproveSensorMutationVariables>;
export const ApproveAllSensorsDocument = gql`
    mutation approveAllSensors {
  approveAllSensor(input: {}) {
    clientMutationId
  }
}
    `;
export type ApproveAllSensorsMutationFn = Apollo.MutationFunction<ApproveAllSensorsMutation, ApproveAllSensorsMutationVariables>;

/**
 * __useApproveAllSensorsMutation__
 *
 * To run a mutation, you first call `useApproveAllSensorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAllSensorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAllSensorsMutation, { data, loading, error }] = useApproveAllSensorsMutation({
 *   variables: {
 *   },
 * });
 */
export function useApproveAllSensorsMutation(baseOptions?: Apollo.MutationHookOptions<ApproveAllSensorsMutation, ApproveAllSensorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveAllSensorsMutation, ApproveAllSensorsMutationVariables>(ApproveAllSensorsDocument, options);
      }
export type ApproveAllSensorsMutationHookResult = ReturnType<typeof useApproveAllSensorsMutation>;
export type ApproveAllSensorsMutationResult = Apollo.MutationResult<ApproveAllSensorsMutation>;
export type ApproveAllSensorsMutationOptions = Apollo.BaseMutationOptions<ApproveAllSensorsMutation, ApproveAllSensorsMutationVariables>;
export const UnapprovedSensorsDocument = gql`
    query UnapprovedSensors($order: SensorFilter_order, $search: String, $pageSize: Int!, $after: String, $before: String) {
  sensors(
    search: $search
    order: [$order]
    first: $pageSize
    after: $after
    before: $before
    approved: false
  ) @connection(key: "unapproved_sensors", filters: ["search", "pageSize"]) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        ...UnapprovedSensor
      }
    }
  }
}
    ${UnapprovedSensorFragmentDoc}`;

/**
 * __useUnapprovedSensorsQuery__
 *
 * To run a query within a React component, call `useUnapprovedSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnapprovedSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnapprovedSensorsQuery({
 *   variables: {
 *      order: // value for 'order'
 *      search: // value for 'search'
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useUnapprovedSensorsQuery(baseOptions: Apollo.QueryHookOptions<UnapprovedSensorsQuery, UnapprovedSensorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnapprovedSensorsQuery, UnapprovedSensorsQueryVariables>(UnapprovedSensorsDocument, options);
      }
export function useUnapprovedSensorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnapprovedSensorsQuery, UnapprovedSensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnapprovedSensorsQuery, UnapprovedSensorsQueryVariables>(UnapprovedSensorsDocument, options);
        }
export type UnapprovedSensorsQueryHookResult = ReturnType<typeof useUnapprovedSensorsQuery>;
export type UnapprovedSensorsLazyQueryHookResult = ReturnType<typeof useUnapprovedSensorsLazyQuery>;
export type UnapprovedSensorsQueryResult = Apollo.QueryResult<UnapprovedSensorsQuery, UnapprovedSensorsQueryVariables>;