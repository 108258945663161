import React, { useState } from "react";
import { Panel } from "../../../component/Panel";
import { Button } from "../../../component/Button";

interface Props {
  onUserTwoFactorAuthReset: (password: string, token: string) => void;
  errorMessages: string[];
  disabled?: boolean;
  disabledReason?: string;
}

export const ResetUserTwoFactorAuthPanel: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ onUserTwoFactorAuthReset, errorMessages, disabled, disabledReason }) => {
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [tokenError, setTokenError] = useState<boolean>(false);

  const verifyInput = () => {
    setErrorMessage(null);
    setPasswordError(false);
    setTokenError(false);

    if (password.trim().length === 0) {
      setErrorMessage("Empty password.");
      setPasswordError(true);
      return false;
    }

    if (token.trim().length === 0) {
      setErrorMessage("Empty token.");
      setTokenError(true);
      return false;
    }

    return true;
  };

  return (
    <Panel>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-8">
          Reset Two Factor Authentication
        </h3>
        <form>
          <p className="pb-5">
            You can reset your Two Factor Autentication configuration here. This
            can be useful if you want to set up authentication on a new device.
            Please provide your password and a current token to prove your
            identity.
          </p>
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Password
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="username"
                type="password"
                disabled={disabled}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
                className={`appearance-none block w-full px-3 py-2 border ${
                  passwordError && "border-red-500"
                } rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              />
            </div>
          </div>

          <div className="mt-6">
            <label
              htmlFor="token"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Token
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                disabled={disabled}
                id="token"
                type="number"
                value={token}
                onChange={(event) => setToken(event.target.value)}
                required
                className={`appearance-none block w-full px-3 py-2 border ${
                  tokenError && "border-red-500"
                } rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              />
            </div>
          </div>

          {!!errorMessage && (
            <p className="text-xs italic text-red-500">{errorMessage}</p>
          )}

          {errorMessages.map((message, index) => (
            <p key={index} className="text-xs italic text-red-500">
              {message}
            </p>
          ))}

          {disabled && (
            <div className="flex justify-center items-center p-4">
              <p>{disabledReason}</p>
            </div>
          )}

          <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
              <Button
                wide={true}
                disabled={disabled}
                onClick={(event) => {
                  event.preventDefault();
                  if (verifyInput()) {
                    onUserTwoFactorAuthReset(password, token);
                    setPassword("");
                    setToken("");
                  }
                }}
              >
                Reset
              </Button>
            </span>
          </div>
        </form>
      </div>
    </Panel>
  );
};
