import { useCallback, useEffect, useMemo, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { Item, Options } from "./useItemSelection";

export interface UrlOptions {
  queryParameterName: string;
}

interface LocalStorageOptions {
  localStorageKey?: string;
}

export interface Result<ItemType> {
  selectedItems: ItemType[];
  selectionCount: number;
  items: ItemType[];
  selectAllItems: () => void;
  clearSelection: () => void;
}

export const useUrlItemSelectionReader = ({
  queryParameterName,
}: UrlOptions) => {
  // We default to the way PHP handles query parameters, to be in sync with how the backend would handle it.
  queryParameterName += "[]";
  const location = useLocation();
  const selectedItemIds = new URLSearchParams(location.search)
    .getAll(queryParameterName)
    .map((id) => atob(id));

  return {
    selectedItemIds,
    selectionCount: selectedItemIds.length,
  };
};

export function useUrlItemSelection<ItemType>({
  items,
  idSelector,
  queryParameterName,
  localStorageKey,
}: Options<ItemType> & UrlOptions & LocalStorageOptions): Result<
  Item<ItemType>
> {
  const { selectedItemIds } = useUrlItemSelectionReader({ queryParameterName });
  const initialLocalStorageKey = useRef(localStorageKey);
  const initialItemIds = useRef(selectedItemIds);

  // We default to the way PHP handles query parameters, to be in sync with how the backend would handle it.
  queryParameterName += "[]";

  const navigate = useNavigate();
  const location = useLocation();

  const setSelectedItemIds = useCallback(
    (ids: string[]) => {
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.delete(queryParameterName);
      ids.forEach((id) => urlSearchParams.append(queryParameterName, btoa(id)));

      const newSearch = urlSearchParams.toString();
      if (newSearch !== location.search) {
        navigate(`${location.pathname}?${newSearch}${location.hash}`);
      }

      if (initialLocalStorageKey.current) {
        localStorage.setItem(
          initialLocalStorageKey.current,
          JSON.stringify(ids)
        );
      }
    },
    [
      location.search,
      location.pathname,
      location.hash,
      queryParameterName,
      navigate,
    ]
  );

  /*
   * itemIds are being joined here to produce a scalar value. This prevents the
   * useEffect hook from being called on every render with a different items array
   * instance.
   */
  const itemIds = items.map((item) => idSelector(item)).join(":");

  // This effect removes any unknown items from the URL
  useEffect(() => {
    const ids = itemIds.split(":").filter((id) => id.trim() !== "");
    if (ids.length > 0) {
      const knownSelectedItemIds = selectedItemIds.filter((id) =>
        ids.includes(id)
      );

      if (selectedItemIds.join(":") !== knownSelectedItemIds.join(":")) {
        setSelectedItemIds(knownSelectedItemIds);
      }
    }
  }, [itemIds, selectedItemIds, setSelectedItemIds]);

  // This effect loads the selection from the localstorage if none is found in the URL
  useEffect(() => {
    if (initialItemIds.current.length === 0) {
      if (!initialLocalStorageKey.current) {
        return;
      }
      const valueFromLocalStorage = localStorage.getItem(
        initialLocalStorageKey.current
      );
      if (valueFromLocalStorage === null) {
        return;
      }
      const itemIdsFromLocalStorage = JSON.parse(valueFromLocalStorage);
      if (
        Array.isArray(itemIdsFromLocalStorage) &&
        itemIdsFromLocalStorage.length > 0
      ) {
        setSelectedItemIds(itemIdsFromLocalStorage);
      }
    }
  }, [initialLocalStorageKey, setSelectedItemIds]);

  // This effect writes the data from the URL to the local storage on initial mounting
  useEffect(() => {
    if (initialLocalStorageKey.current) {
      if (initialItemIds.current.length > 0) {
        localStorage.setItem(
          initialLocalStorageKey.current,
          JSON.stringify(initialItemIds.current)
        );
      }
    }
  }, []);

  const itemsWithToggle = useMemo(
    () =>
      items.map((item) => {
        const itemId = idSelector(item);
        const isSelected = selectedItemIds.includes(itemId);
        return {
          item,
          selectMultipleItems: (itemIds: string[]) => {
            setSelectedItemIds([...selectedItemIds, ...itemIds]);
          },
          deselectMultipleItems: (itemIds: string[]) => {
            setSelectedItemIds(
              selectedItemIds.filter(
                (selectedItemId) => !itemIds.includes(selectedItemId)
              )
            );
          },
          toggle: () => {
            if (isSelected) {
              setSelectedItemIds(selectedItemIds.filter((id) => id !== itemId));
            } else {
              const urlSearchParams = new URLSearchParams(location.search);
              urlSearchParams.append(`${queryParameterName}`, btoa(itemId));
              const newSearch = urlSearchParams.toString();
              navigate(`${location.pathname}?${newSearch}${location.hash}`);
            }
          },
          isSelected,
        };
      }),
    [
      items,
      idSelector,
      selectedItemIds,
      setSelectedItemIds,
      location.search,
      location.pathname,
      location.hash,
      queryParameterName,
      navigate,
    ]
  );

  const selectedItems = itemsWithToggle.filter(({ item }) =>
    selectedItemIds.includes(idSelector(item))
  );

  const result: Result<Item<ItemType>> = {
    selectedItems,
    selectionCount: selectedItems.length,
    items: itemsWithToggle,
    selectAllItems: () =>
      setSelectedItemIds(items.map((item) => idSelector(item))),
    clearSelection: () => setSelectedItemIds([]),
  };

  return result;
}
