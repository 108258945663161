import React, { useState } from "react";
import { LoginPanel } from "./LoginPanel";
import { AppVersion } from "../../../component/AppVersion";

interface Props {
  errorMessage: string | null;
  verifyLogin: (username: string, password: string) => void;
}

export const LoginScreen: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ errorMessage, verifyLogin }) => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  return (
    <LoginPanel>
      <h3 className="pt-4 text-2xl text-center">Welcome Back!</h3>
      <form
        className="px-8 pt-6 pb-8 mb-4 bg-white rounded"
        onSubmit={(event) => {
          event.preventDefault();
          verifyLogin(username, password);
        }}
      >
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-bold text-gray-700"
            htmlFor="username"
          >
            Username
          </label>
          <input
            className={`w-full px-3 py-2 text-sm leading-tight text-gray-700 border ${
              !!errorMessage && password.length > 0 && "border-red-500"
            } rounded shadow appearance-none focus:outline-none focus:shadow-outline`}
            id="username"
            type="text"
            placeholder="Username"
            value={username}
            autoCapitalize="none"
            autoCorrect="off"
            autoFocus
            onChange={(event) => setUsername(event.target.value)}
          />
        </div>

        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-bold text-gray-700"
            htmlFor="password"
          >
            Password
          </label>
          <input
            className={`w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border ${
              !!errorMessage && username.length > 0 && "border-red-500"
            } rounded shadow appearance-none focus:outline-none focus:shadow-outline`}
            id="password"
            type="password"
            placeholder="******************"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          {!!errorMessage && (
            <p className="text-xs italic text-red-500">{errorMessage}</p>
          )}
        </div>
        <div className="mb-6 text-center">
          <button
            className="w-full px-4 py-2 font-bold text-white bg-primary-500 rounded-full hover:bg-primary-600 focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Sign In
          </button>
        </div>
      </form>
      <div className="text-xs text-gray-500 text-center">
        <AppVersion />
      </div>
    </LoginPanel>
  );
};
