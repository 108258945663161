import { FetchPolicy, gql } from "@apollo/client";
import { useEffect } from "react";
import { useEnvironment } from "../../../core/environment";
import { SensorRowFragment } from "../../../graphql/Sensor.generated";
import { nodesFromCollectionQuery } from "../../../helper/nodesFromCollectionQuery";
import { SensorsQueryVariables, useSensorsQuery } from "./useSensors.generated";

export const SENSORS = gql`
  query Sensors(
    $order: SensorFilter_order
    $search: String
    $favorite: Boolean
    $pageSize: Int!
    $after: String
    $before: String
    $sensorGroups: String
    $approved: Boolean
  ) {
    sensors(
      sensorGroups: $sensorGroups
      search: $search
      order: [$order]
      favorite: $favorite
      first: $pageSize
      after: $after
      before: $before
      approved: $approved
    ) @connection(key: "sensors", filters: ["search", "pageSize"]) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          ...SensorRow
        }
      }
    }
  }
`;

interface Configuration {
  variables: SensorsQueryVariables;
  poll?: boolean;
  fetchPolicy?: FetchPolicy;
}

export const useSensors = ({ variables, poll, fetchPolicy }: Configuration) => {
  const { environment } = useEnvironment();
  const queryResult = useSensorsQuery({
    variables,
    pollInterval: poll ? 10000 : 0,
    notifyOnNetworkStatusChange: true,
    fetchPolicy,
  });

  const { refetch } = queryResult;

  // This is being used to identify when we need to refetch - and yes, that
  const variablesString = JSON.stringify(variables);
  useEffect(() => {
    refetch();
  }, [variablesString, refetch]);

  const fetchWithSensorsRefetch = (url: string, init?: RequestInit): void => {
    fetch(environment.baseUrl + url, {
      credentials: "include",
      method: "POST",
      ...init,
    }).then(() => refetch());
  };
  const addCommentToSensors = async (
    sensors: SensorRowFragment[],
    comment: string
  ) => {
    fetchWithSensorsRefetch("/api/batch/sensors/comment", {
      body: JSON.stringify({
        deviceNames: sensors.map((sensor) => sensor.deviceName),
        comment,
      }),
    });
  };

  const disconnectAllSensors = async () => {
    fetchWithSensorsRefetch("/api/sensors/disconnectAll");
  };

  const setSensorsShouldConnect = async (
    sensors: SensorRowFragment[],
    shouldConnect: boolean
  ) => {
    fetchWithSensorsRefetch("/api/batch/sensors/shouldConnect", {
      body: JSON.stringify({
        deviceNames: sensors.map((sensor) => sensor.deviceName),
        shouldConnect,
      }),
    });
  };

  const deleteSensors = async (sensors: SensorRowFragment[]) => {
    fetchWithSensorsRefetch("/api/batch/sensors/delete", {
      body: JSON.stringify({
        deviceNames: sensors.map((sensor) => sensor.deviceName),
      }),
    });
  };

  const setSensorsFavorited = async (
    sensors: SensorRowFragment[],
    favorite: boolean
  ) => {
    fetchWithSensorsRefetch("/api/batch/sensors/favorite", {
      body: JSON.stringify({
        deviceNames: sensors.map((sensor) => sensor.deviceName),
        favorite,
      }),
    });
  };

  const sensors: SensorRowFragment[] = nodesFromCollectionQuery(
    queryResult.data?.sensors
  );

  return {
    queryResult,
    sensors,
    refetch,
    addCommentToSensors,
    deleteSensors,
    disconnectAllSensors,
    setSensorsFavorited,
    setSensorsShouldConnect,
  };
};
