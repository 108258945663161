import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { BackupApplicationSettingsFragmentDoc } from '../../graphql/ApplicationSettings.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSensorMutationVariables = Types.Exact<{
  deviceName: Types.Scalars['String'];
  type: Types.Scalars['String'];
}>;


export type CreateSensorMutation = { __typename?: 'Mutation', createSensor?: { __typename?: 'createSensorPayload', sensor?: { __typename?: 'Sensor', deviceName: string, type: string } | null } | null };

export type ApplicationSettingsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ApplicationSettingsQuery = { __typename?: 'Query', applicationSettings?: { __typename?: 'ApplicationSettings', backupIntervalLength: number, backupRetentionCount: number, backupIntervalUnit: string } | null };

export type UpdateApplicationSettingsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  backupRetentionCount?: Types.InputMaybe<Types.Scalars['Int']>;
  backupIntervalLength?: Types.InputMaybe<Types.Scalars['Int']>;
  backupIntervalUnit?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateApplicationSettingsMutation = { __typename?: 'Mutation', updateApplicationSettings?: { __typename?: 'updateApplicationSettingsPayload', applicationSettings?: { __typename?: 'ApplicationSettings', backupIntervalLength: number, backupRetentionCount: number, backupIntervalUnit: string } | null } | null };


export const CreateSensorDocument = gql`
    mutation createSensor($deviceName: String!, $type: String!) {
  createSensor(input: {deviceName: $deviceName, type: $type}) {
    sensor {
      deviceName
      type
    }
  }
}
    `;
export type CreateSensorMutationFn = Apollo.MutationFunction<CreateSensorMutation, CreateSensorMutationVariables>;

/**
 * __useCreateSensorMutation__
 *
 * To run a mutation, you first call `useCreateSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSensorMutation, { data, loading, error }] = useCreateSensorMutation({
 *   variables: {
 *      deviceName: // value for 'deviceName'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateSensorMutation(baseOptions?: Apollo.MutationHookOptions<CreateSensorMutation, CreateSensorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSensorMutation, CreateSensorMutationVariables>(CreateSensorDocument, options);
      }
export type CreateSensorMutationHookResult = ReturnType<typeof useCreateSensorMutation>;
export type CreateSensorMutationResult = Apollo.MutationResult<CreateSensorMutation>;
export type CreateSensorMutationOptions = Apollo.BaseMutationOptions<CreateSensorMutation, CreateSensorMutationVariables>;
export const ApplicationSettingsDocument = gql`
    query applicationSettings($id: ID!) {
  applicationSettings(id: $id) {
    ...BackupApplicationSettings
  }
}
    ${BackupApplicationSettingsFragmentDoc}`;

/**
 * __useApplicationSettingsQuery__
 *
 * To run a query within a React component, call `useApplicationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicationSettingsQuery(baseOptions: Apollo.QueryHookOptions<ApplicationSettingsQuery, ApplicationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationSettingsQuery, ApplicationSettingsQueryVariables>(ApplicationSettingsDocument, options);
      }
export function useApplicationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationSettingsQuery, ApplicationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationSettingsQuery, ApplicationSettingsQueryVariables>(ApplicationSettingsDocument, options);
        }
export type ApplicationSettingsQueryHookResult = ReturnType<typeof useApplicationSettingsQuery>;
export type ApplicationSettingsLazyQueryHookResult = ReturnType<typeof useApplicationSettingsLazyQuery>;
export type ApplicationSettingsQueryResult = Apollo.QueryResult<ApplicationSettingsQuery, ApplicationSettingsQueryVariables>;
export const UpdateApplicationSettingsDocument = gql`
    mutation updateApplicationSettings($id: ID!, $backupRetentionCount: Int, $backupIntervalLength: Int, $backupIntervalUnit: String) {
  updateApplicationSettings(
    input: {id: $id, backupIntervalLength: $backupIntervalLength, backupIntervalUnit: $backupIntervalUnit, backupRetentionCount: $backupRetentionCount}
  ) {
    applicationSettings {
      ...BackupApplicationSettings
    }
  }
}
    ${BackupApplicationSettingsFragmentDoc}`;
export type UpdateApplicationSettingsMutationFn = Apollo.MutationFunction<UpdateApplicationSettingsMutation, UpdateApplicationSettingsMutationVariables>;

/**
 * __useUpdateApplicationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationSettingsMutation, { data, loading, error }] = useUpdateApplicationSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      backupRetentionCount: // value for 'backupRetentionCount'
 *      backupIntervalLength: // value for 'backupIntervalLength'
 *      backupIntervalUnit: // value for 'backupIntervalUnit'
 *   },
 * });
 */
export function useUpdateApplicationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicationSettingsMutation, UpdateApplicationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicationSettingsMutation, UpdateApplicationSettingsMutationVariables>(UpdateApplicationSettingsDocument, options);
      }
export type UpdateApplicationSettingsMutationHookResult = ReturnType<typeof useUpdateApplicationSettingsMutation>;
export type UpdateApplicationSettingsMutationResult = Apollo.MutationResult<UpdateApplicationSettingsMutation>;
export type UpdateApplicationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateApplicationSettingsMutation, UpdateApplicationSettingsMutationVariables>;