import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SensorGroupNodeFragmentDoc } from '../../../graphql/SensorGroup.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SensorGroupsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
}>;


export type SensorGroupsQuery = { __typename?: 'Query', sensorGroups?: { __typename?: 'SensorGroupCursorConnection', edges?: Array<{ __typename?: 'SensorGroupEdge', node?: { __typename?: 'SensorGroup', id: string, name: string, isExpanded: boolean, parent?: { __typename?: 'SensorGroup', id: string } | null, sensors?: { __typename?: 'SensorCursorConnection', totalCount: number } | null } | null } | null> | null } | null };


export const SensorGroupsDocument = gql`
    query SensorGroups($limit: Int!) {
  sensorGroups(first: $limit) {
    edges {
      node {
        ...SensorGroupNode
      }
    }
  }
}
    ${SensorGroupNodeFragmentDoc}`;

/**
 * __useSensorGroupsQuery__
 *
 * To run a query within a React component, call `useSensorGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorGroupsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSensorGroupsQuery(baseOptions: Apollo.QueryHookOptions<SensorGroupsQuery, SensorGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorGroupsQuery, SensorGroupsQueryVariables>(SensorGroupsDocument, options);
      }
export function useSensorGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorGroupsQuery, SensorGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorGroupsQuery, SensorGroupsQueryVariables>(SensorGroupsDocument, options);
        }
export type SensorGroupsQueryHookResult = ReturnType<typeof useSensorGroupsQuery>;
export type SensorGroupsLazyQueryHookResult = ReturnType<typeof useSensorGroupsLazyQuery>;
export type SensorGroupsQueryResult = Apollo.QueryResult<SensorGroupsQuery, SensorGroupsQueryVariables>;