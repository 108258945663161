import React from "react";
import { SensorRowFragment } from "../../../graphql/Sensor.generated";

interface Props {
  sensor: Pick<SensorRowFragment, "connectionStatus">;
}

export const SensorStatus: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ sensor }) => {
  if (sensor.connectionStatus === "connected") {
    return (
      <span className="text-2xl font-medium text-tradewind-500 px-1 align-middle">
        <i className="icon-cloud_check" />
      </span>
    );
  }

  if (sensor.connectionStatus === "timeout") {
    // timed out
    return (
      <span className="text-2xl font-medium text-red-500 px-1 align-middle">
        <i className="icon-cloud_disconnected" />
      </span>
    );
  }

  if (sensor.connectionStatus === "awaiting_connection") {
    return (
      <span className="text-2xl font-medium text-selective-yellow-400 px-1 rounded align-middle">
        <i className="icon-cloud_update"></i>
      </span>
    );
  }

  return (
    <span className="text-2xl font-medium text-blue-500 px-1 rounded align-middle">
      <i className="icon-cloud_awaiting"></i>
    </span>
  );
};
