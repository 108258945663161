import React from "react";
import Image from "../../../images/R07_INTEGRATE-1.png";

interface Props {}

export const LoginPanel: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ children }) => {
  return (
    <div className="container mx-auto">
      <div className="flex justify-center px-6 my-12">
        <div className="w-full xl:w-3/4 lg:w-11/12 flex">
          <div className="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg">
            <img alt="Entrance with APS" src={Image} />
          </div>
          <div className="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none flex flex-col justify-center">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
