import React, { useState } from "react";
import { LoginPanel } from "../LoginPanel";

interface Props {
  errorMessage: string | null;
  verifyToken: (token: number) => void;
}

export const VerifyCode: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ verifyToken, errorMessage }) => {
  const [token, setToken] = useState("");
  return (
    <LoginPanel>
      <h3 className="text-2xl text-center">
        Verify Two Factor Authentication Setup
      </h3>
      <form
        className="px-8 pt-6 bg-white rounded"
        onSubmit={(event) => {
          event.preventDefault();
          verifyToken(Number(token));
        }}
      >
        <div className="mb-5">
          <label
            className="block text-sm font-bold text-gray-700"
            htmlFor="token"
          ></label>
          <input
            className={`w-full px-3 py-2 text-sm leading-tight text-gray-700 border ${
              !!errorMessage && token.length > 0 && "border-red-500"
            } rounded shadow appearance-none focus:outline-none focus:shadow-outline`}
            id="token"
            type="number"
            placeholder="123456"
            value={token}
            onChange={(event) => setToken(event.target.value)}
          />
          {!!errorMessage && (
            <p className="text-xs italic text-red-500">{errorMessage}</p>
          )}

          <p className="text-center mt-8">
            Please enter the One-Time-Password generated by your authenticator
            app in the input field above to make sure everything is set up
            correctly.
          </p>
        </div>

        <div className="mb-6 text-center">
          <button
            className="w-full px-4 py-2 font-bold text-white bg-sunglow-500 rounded-full hover:bg-sunglow-600 focus:outline-none focus:shadow-outline"
            type="submit"
            disabled={!token.length}
          >
            Verify
          </button>
        </div>
      </form>
    </LoginPanel>
  );
};
