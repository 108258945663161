import React from "react";
import { Pagination } from "./Pagination";

export interface PageVariables {
  after?: string | null | undefined;
  before?: string | null | undefined;
}

interface Props {
  pageInfo?: {
    endCursor?: string | null;
    startCursor?: string | null;
    hasNextPage: boolean | null;
    hasPreviousPage: boolean | null;
  };

  fetchMore: (config: {
    variables: {
      after?: string | null;
      before?: string | null;
    };
  }) => any;
  onPageChange?: (variables: PageVariables) => void;
}

export const PaginationApollo: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ pageInfo, fetchMore, onPageChange, children }) => {
  if (!pageInfo) {
    return null;
  }

  return (
    <Pagination
      children={children}
      hasNextPage={!!pageInfo.hasNextPage}
      hasPreviousPage={!!pageInfo.hasPreviousPage}
      onNext={() => {
        const variables = {
          after: pageInfo.endCursor,
          before: undefined,
        };
        fetchMore({ variables });
        onPageChange && onPageChange(variables);
      }}
      onPrevious={() => {
        const variables = {
          after: undefined,
          before: pageInfo.startCursor,
        };
        fetchMore({
          variables,
        });
        onPageChange && onPageChange(variables);
      }}
    />
  );
};
