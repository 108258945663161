import { Item } from "../../../hook/useItemSelection";
import { SensorGroupNodeFragment } from "../../../graphql/SensorGroup.generated";
import keyBy from "lodash/keyBy";

type SensorGroupByIdMap = Record<string, Item<SensorGroupNodeFragment>>;
/**
 * Filter sensor groups by a search string, while keeping parents of found nodes.
 */
export const filterGroups = (
  sensorGroupNodes: Item<SensorGroupNodeFragment>[],
  filter: string
): Item<SensorGroupNodeFragment>[] => {
  filter = filter.toLowerCase();
  const byId: SensorGroupByIdMap = keyBy(
    sensorGroupNodes,
    (sensorGroupNode) => sensorGroupNode.item.id
  );

  const keepIds: string[] = [];
  sensorGroupNodes.forEach((sensorGroupNode) => {
    if (sensorGroupNode.item.name.toLowerCase().includes(filter)) {
      keepIds.push(...getIdIncludingParentIds(sensorGroupNode, byId));
    }
  });

  return sensorGroupNodes.filter((sensorGroup) =>
    keepIds.includes(sensorGroup.item.id)
  );
};

const getIdIncludingParentIds = (
  sensorGroup: Item<SensorGroupNodeFragment>,
  byId: SensorGroupByIdMap
): string[] => {
  if (!sensorGroup.item.parent) {
    return [sensorGroup.item.id];
  }
  return [
    sensorGroup.item.id,
    ...getIdIncludingParentIds(byId[sensorGroup.item.parent.id], byId),
  ];
};
