import React, { useState } from "react";
import { gql } from "@apollo/client";
import {
  SensorFirmwaresQueryVariables,
  useDeleteFirmwareMutation,
  useSensorFirmwaresQuery,
  useUploadFirmwareMutation,
} from "./SensorFirmwareScene.generated";
import { nodesFromCollectionQuery } from "../../../helper/nodesFromCollectionQuery";
import {
  PageVariables,
  PaginationApollo,
} from "../../../component/PaginationApollo";
import {
  useNumberUrlState,
  useObjectUrlState,
} from "../../../hook/useUrlState";
import { SensorFirmwareTable } from "./component/SensorFirmwareTable";
import { PageSizeSelector } from "../../../component/PageSizeSelector";
import { Button } from "../../../component/Button";
import { SensorFirmwareUploadDialog } from "./component/SensorFirmwareUploadDialog";
import { useToggle } from "../../../hook/useToggle";
import { ConfirmationDialog } from "../../../component/ConfirmationDialog";

export interface SensorFirmware {
  id: string;
  name: string;
  contentUrl?: string | null;
}

export const SENSOR_FIRMWARES = gql`
  query SensorFirmwares($pageSize: Int!, $after: String, $before: String) {
    sensorFirmware(first: $pageSize, after: $after, before: $before)
      @connection(key: "sensorFirmwares", filters: ["name", "pageSize"]) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          id
          name
          contentUrl
        }
      }
    }
  }
`;

export const UPLOAD_FIRMWARE = gql`
  mutation UploadFirmware($name: String!, $file: Upload!) {
    uploadSensorFirmware(input: { name: $name, file: $file }) {
      sensorFirmware {
        id
        name
        contentUrl
      }
    }
  }
`;

export const DELETE_FIRMWARE = gql`
  mutation DeleteFirmware($id: ID!) {
    deleteSensorFirmware(input: { id: $id }) {
      sensorFirmware {
        id
      }
    }
  }
`;

export const SensorFirmwareScene: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadModalOpen, toggleUploadModal] = useToggle(false);
  const [selectedFirmware, setSelectedFirmware] =
    useState<SensorFirmware | null>(null);
  const [deleteModalOpen, toggleDeleteModal] = useToggle(false);
  const [pageSize, setPageSize] = useNumberUrlState("pageSize", 25);
  const [pageVariables, setPageVariables] = useObjectUrlState<PageVariables>(
    "pageInfo",
    {}
  );
  const queryVariables: SensorFirmwaresQueryVariables = {
    pageSize,
    ...pageVariables,
  };
  const { data, fetchMore, refetch } = useSensorFirmwaresQuery({
    variables: queryVariables,
  });

  const [uploadFirmware] = useUploadFirmwareMutation();
  const [deleteFirmware] = useDeleteFirmwareMutation();

  if (!data || !data.sensorFirmware) {
    return null;
  }

  const firmwares: SensorFirmware[] = nodesFromCollectionQuery(
    data.sensorFirmware
  );

  const showFirmwareUploadDialog = () => {
    toggleUploadModal();
  };

  const handleUploadConfirmation = (name: string, file: File) => {
    setUploading(true);
    uploadFirmware({
      variables: {
        name,
        file,
      },
      context: {
        fetchOptions: {
          onProgress: (event: ProgressEvent) => {
            setUploadPercentage((event.loaded / event.total) * 100);
          },
        },
      },
    }).finally(() => {
      refetch();
      toggleUploadModal();
      setUploading(false);
      setUploadPercentage(0);
    });
  };

  const handleDeleteFirmware = (firmware: SensorFirmware) => {
    setSelectedFirmware(firmware);
    toggleDeleteModal();
  };

  const doDeleteFirmware = () => {
    if (!selectedFirmware) {
      return;
    }

    deleteFirmware({
      variables: {
        id: selectedFirmware.id,
      },
    }).finally(refetch);

    toggleDeleteModal();
    setSelectedFirmware(null);
  };

  return (
    <>
      <div className="flex flex-col max-h-full container mx-auto px-4 sm:px-8">
        <div className="flex-none py-4">
          <div className="flex items-center">
            <h2 className="text-2xl font-semibold leading-tight mr-4">
              Sensor Firmwares
            </h2>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex flex-row items-center">
              <PageSizeSelector
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
            </div>
            <div>
              <Button onClick={showFirmwareUploadDialog}>
                Upload Firmware
              </Button>
            </div>
          </div>
        </div>
        <SensorFirmwareTable
          firmwares={firmwares}
          onDeleteFirmware={handleDeleteFirmware}
          footer={
            <div className="w-full h-12 px-4">
              <PaginationApollo
                pageInfo={data.sensorFirmware?.pageInfo}
                fetchMore={fetchMore}
                onPageChange={setPageVariables}
              >
                <span className="text-xs xs:text-sm text-gray-900 float-right py-5 pr-4">
                  Total of {data.sensorFirmware?.totalCount.toLocaleString()}{" "}
                  firmware versions
                </span>
              </PaginationApollo>
            </div>
          }
        />
      </div>
      <SensorFirmwareUploadDialog
        show={uploadModalOpen}
        uploadPercentage={uploadPercentage}
        uploading={uploading}
        onConfirm={handleUploadConfirmation}
        onCancel={toggleUploadModal}
      />
      <ConfirmationDialog
        show={deleteModalOpen}
        title={"Delete Firmware"}
        message={`Do you really want to delete the firmware ${selectedFirmware?.name}?`}
        confirmButtonText={"Delete"}
        cancelButtonText={"Cancel"}
        onConfirm={doDeleteFirmware}
        onCancel={() => {
          toggleDeleteModal();
        }}
      />
    </>
  );
};
