export class FileDownloader {
  public async downloadFile(
    filename: string,
    url: string,
    method: string,
    headers: Headers
  ) {
    const anchor = document.createElement("a");
    document.body.appendChild(anchor);

    const response = await fetch(url, {
      credentials: "include",
      headers,
      method,
    });
    const dataBlob = await response.blob();

    const objectUrl = window.URL.createObjectURL(dataBlob);

    anchor.href = objectUrl;
    anchor.download = filename;
    anchor.click();

    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(objectUrl);
  }
}
