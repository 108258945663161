import React, { ReactNode, useState } from "react";
import { CellProps, Column, HeaderProps, useTable } from "react-table";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/pro-solid-svg-icons";
import { SensorFilter_Order } from "../../../../generated/graphql";
import { SensorApprovalTableRow } from "./SensorApprovalTableRow";
import { Button } from "../../../../component/Button";
import { UnapprovedSensorFragment } from "../SensorApprovalScene.generated";

interface Props {
  sensors: UnapprovedSensorFragment[];
  loading: boolean;
  onApprove: (id: string) => void;
  onApproveAll: () => void;
  onSortChanged: (
    field: keyof SensorFilter_Order,
    direction: "asc" | "desc"
  ) => void;
  footer?: ReactNode;
}

type SensorApprovalTableCellProps = CellProps<UnapprovedSensorFragment> & {
  onApprove: (id: string) => void;
};

type SensorApprovalTableHeaderProps = HeaderProps<UnapprovedSensorFragment> & {
  onApproveAll: () => void;
};

const columns: Column<UnapprovedSensorFragment>[] = [
  {
    id: "Device",
    Header: (props: HeaderProps<UnapprovedSensorFragment>) => {
      return (
        <div
          onClick={(event) => {
            // @ts-ignore
            props.onColumnHeaderClicked("deviceName");
          }}
          className="cursor-pointer"
        >
          <span>Device</span>
          <div className="w-4 ml-1 inline-block">
            {/* @ts-ignore */}
            {props.sortField === "deviceName" && (
              <FontAwesomeIcon
                // @ts-ignore
                icon={props.sortDirection === "asc" ? faSortUp : faSortDown}
                className="ml-1 w-4"
              />
            )}
          </div>
        </div>
      );
    },
    Cell: (props: CellProps<UnapprovedSensorFragment>) => {
      return (
        <Link
          to={`/sensor/${encodeURIComponent(props.row.original.id)}`}
          className="select-none font-mono text-xs text-gray-800 hover:text-gray-600 hover:underline"
        >
          {props.row.original.deviceName}
        </Link>
      );
    },
  },
  {
    Header: "Product",
    accessor: (row: UnapprovedSensorFragment) => row.status?.modelType,
  },
  {
    Header: "MAC Address",
    accessor: (row: UnapprovedSensorFragment) => row.status?.macAddress,
  },
  {
    id: "Approval",
    // @ts-ignore
    Header: (props: SensorApprovalTableHeaderProps) => {
      return (
        <div className="text-right">
          <Button onClick={props.onApproveAll}>Approve all</Button>
        </div>
      );
    },
    // @ts-ignore
    Cell: (props: SensorApprovalTableCellProps) => {
      return (
        <div className="text-right">
          <Button onClick={() => props.onApprove(props.row.original.id)}>
            Approve
          </Button>
        </div>
      );
    },
  },
];
export const SensorApprovalTable: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ sensors, onSortChanged, onApprove, onApproveAll, footer }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: sensors });

  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortField, setSortField] = useState<string | null>(null);

  const onColumnHeaderClicked = (field: keyof SensorFilter_Order) => {
    const direction = sortDirection === "asc" ? "desc" : "asc";

    setSortDirection(direction);
    setSortField(field);

    onSortChanged(field, direction);
  };

  return (
    <React.Fragment>
      <div className="flex -mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto overflow-y-hidden">
        <div className="flex flex-col flex-grow min-w-full shadow rounded-lg overflow-hidden">
          <div className="flex-grow overflow-y-auto">
            <table {...getTableProps()} className="min-w-full leading-normal">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="sticky top-0 z-20 px-5 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider"
                      >
                        {column.render("Header", {
                          onColumnHeaderClicked,
                          onApproveAll,
                          sortField,
                          sortDirection,
                        })}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <SensorApprovalTableRow
                      key={row.original.id}
                      row={row}
                      onApproveSensor={onApprove}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex-none flex mb-4">{footer && <>{footer}</>}</div>
        </div>
      </div>
    </React.Fragment>
  );
};
