import React, { useState } from "react";
import { Panel } from "../../../component/Panel";
import { Button } from "../../../component/Button";

interface Props {
  onControlClientAdded: (hostname: string) => void;
  errorMessages?: string[];
  disabled?: boolean;
}

export const CreateControlClientPanel: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ errorMessages, disabled, onControlClientAdded }) => {
  const [hostname, setHostname] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [hostnameError, setHostnameError] = useState<boolean>(false);

  const isHostnameValid = (hostname: string) => {
    const hostnameExpression = new RegExp("^[a-zA-Z0-9][a-zA-Z0-9_-]*$");

    return hostnameExpression.exec(hostname) !== null;
  };

  const verifyInput = () => {
    setErrorMessage(null);
    setHostnameError(false);

    if (!isHostnameValid(hostname)) {
      setErrorMessage("Invalid hostname");
      setHostnameError(true);

      return false;
    }

    return true;
  };

  return (
    <Panel>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
          Add control client
        </h3>
        <form>
          <div>
            <label
              htmlFor="hostname"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Hostname
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="hostname"
                type="hostname"
                value={hostname}
                onChange={(event) => setHostname(event.target.value)}
                required
                className={`appearance-none block w-full px-3 py-2 border ${
                  hostnameError && "border-red-500"
                } rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              />
            </div>
          </div>

          {!!errorMessage && (
            <p className="text-xs italic text-red-500">{errorMessage}</p>
          )}

          {errorMessages?.map((message, index) => (
            <p key={index} className="text-xs italic text-red-500">
              {message}
            </p>
          ))}

          <div className="mt-6 flex justify-end">
            <Button
              disabled={disabled}
              onClick={(event) => {
                event.preventDefault();
                if (verifyInput()) {
                  onControlClientAdded(hostname);
                  setHostname("");
                }
              }}
            >
              Add control client
            </Button>
          </div>
        </form>
      </div>
    </Panel>
  );
};
