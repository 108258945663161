import React, { useState, useRef } from "react";
import {
  SuccessAlert,
  SuccessAlertDuration,
} from "../../component/SuccessAlert";
import { useCurrentUserQuery } from "../../component/AuthenticatedNavigation.generated";
import { ChangeUserPasswordPanel } from "../UserManagement/component/ChangeUserPasswordPanel";
import { ResetUserTwoFactorAuthPanel } from "../UserManagement/component/ResetUserTwoFactorAuthPanel";
import { gql } from "@apollo/client";
import {
  useChangeOwnPasswordForUserMutation,
  useUpdateSensorCredentialsMutation,
} from "./ProfileScene.generated";
import { useResetOwnTwoFactorAuthForUserMutation } from "./ProfileScene.generated";
import { SensorCredentialsPanel } from "./component/SensorCredentialsPanel";

interface Props {}

export const CHANGE_USER_PASSWORD = gql`
  mutation changeOwnPasswordForUser($password: String!) {
    changeOwnPasswordForUser(input: { password: $password }) {
      user {
        id
      }
    }
  }
`;

export const RESET_USER_TWO_FACTOR_AUTH = gql`
  mutation resetOwnTwoFactorAuthForUser($password: String!, $token: String!) {
    resetOwnTwoFactorAuthForUser(
      input: { password: $password, token: $token }
    ) {
      user {
        id
      }
    }
  }
`;

export const UPDATE_SENSOR_CREDENTIALS = gql`
  mutation updateSensorCredentials(
    $id: ID!
    $username: String!
    $password: String!
  ) {
    updateSensorCredentials(
      input: { id: $id, username: $username, password: $password }
    ) {
      sensorCredentials {
        id
        username
        password
      }
    }
  }
`;

export const ProfileScene: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = (props: Props) => {
  const passwordChangeFeedbackTimer = useRef<number | null>(null);
  const [successAlertDisplayed, setSuccessAlertDisplayed] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { data, refetch } = useCurrentUserQuery();
  const [changeUserPassword, { error: passwordChangeError, loading }] =
    useChangeOwnPasswordForUserMutation();
  const [resetTwoFactorAuth, { error: resetAuthError, loading: resetLoading }] =
    useResetOwnTwoFactorAuthForUserMutation();
  const [
    updateSensorCredentials,
    { error: updateCredentialsError, loading: updateCredentialsLoading },
  ] = useUpdateSensorCredentialsMutation();

  const onUserPasswordChange = (password: string) => {
    changeUserPassword({ variables: { password } })
      .then(() => {
        setAlertMessage("Password changed");
        setSuccessAlertDisplayed(true);
        passwordChangeFeedbackTimer.current = window.setTimeout(() => {
          setSuccessAlertDisplayed(false);
        }, SuccessAlertDuration.ALERT_DURATION_MEDIUM);
      })
      .catch() // Errors handled separately
      .finally(refetch);
  };

  const onUserTwoFactorAuthReset = (password: string, token: string) => {
    resetTwoFactorAuth({ variables: { password, token } })
      .then(() => {
        setAlertMessage("Two Factor Authentication reset");
        setSuccessAlertDisplayed(true);
        passwordChangeFeedbackTimer.current = window.setTimeout(() => {
          setSuccessAlertDisplayed(false);
        }, SuccessAlertDuration.ALERT_DURATION_MEDIUM);
      })
      .catch() // Errors handled separately
      .finally(refetch);
  };

  const handleSensorCredentialsChanged = (
    username: string,
    password: string
  ) => {
    if (!data?.currentUser?.sensorCredentials) {
      return;
    }

    updateSensorCredentials({
      variables: {
        id: data?.currentUser?.sensorCredentials.id,
        username,
        password,
      },
    })
      .catch() // Errors handled separately
      .finally(refetch);
  };

  const isSuperAdminUser =
    data?.currentUser?.roles.includes("ROLE_SUPER_ADMIN");

  const user = data?.currentUser;
  const sensorCredentials = user?.sensorCredentials;

  if (!user || !sensorCredentials) {
    return null;
  }

  return (
    <>
      <SuccessAlert
        show={successAlertDisplayed}
        message={alertMessage}
        onDismiss={() => {
          setSuccessAlertDisplayed(false);
        }}
      ></SuccessAlert>
      <div className="flex flex-col container lg:w-1/3 mx-auto">
        <div className="flex-shrink-0">
          <SensorCredentialsPanel
            username={sensorCredentials.username}
            password={sensorCredentials.password}
            disabled={updateCredentialsLoading}
            onCredentialsChanged={handleSensorCredentialsChanged}
            errorMessages={
              !updateCredentialsError
                ? []
                : updateCredentialsError.graphQLErrors.map(
                    ({ message }) => message
                  )
            }
          ></SensorCredentialsPanel>
          <ChangeUserPasswordPanel
            disabled={isSuperAdminUser || loading}
            disabledReason={
              isSuperAdminUser
                ? "Super admin passwords can only be changed in the installer ini file."
                : undefined
            }
            onUserPasswordChange={onUserPasswordChange}
            errorMessages={
              !passwordChangeError
                ? []
                : passwordChangeError.graphQLErrors.map(
                    ({ message }) => message
                  )
            }
          ></ChangeUserPasswordPanel>
        </div>
        {user.twoFactorAuthEnabled && (
          <div className="flex-shrink-0">
            <ResetUserTwoFactorAuthPanel
              disabled={isSuperAdminUser || resetLoading}
              disabledReason={
                isSuperAdminUser
                  ? "Super admin Two Factor Authentication can only be changed using the maintenance CLI."
                  : undefined
              }
              onUserTwoFactorAuthReset={onUserTwoFactorAuthReset}
              errorMessages={
                !resetAuthError
                  ? []
                  : resetAuthError.graphQLErrors.map(({ message }) => message)
              }
            ></ResetUserTwoFactorAuthPanel>
          </div>
        )}
      </div>
    </>
  );
};
