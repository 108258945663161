import React from "react";
import { SensorGroupNodeFragment } from "../../../../../graphql/SensorGroup.generated";
import { Item } from "../../../../../hook/useItemSelection";
import { useSetSensorGroupMutation } from "./SensorGroupTreeNode.generated";
import { useDrop } from "../../../../../hook/dragAndDrop/useDrop";

interface Props {
  group: Item<SensorGroupNodeFragment>;
}

interface DropCollectedProps {
  isOver: boolean;
  canDrop: boolean;
}

export const IncomingGroupTreeNode: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ group }) => {
  const [setSensorGroup] = useSetSensorGroupMutation({
    refetchQueries: ["Sensors"],
  });

  const [{ isOver, canDrop }, drop] = useDrop<DropCollectedProps>({
    accept: ["SENSORS"],
    drop: (item, monitor) => {
      if (item.type === "SENSORS") {
        item.draggedSensorIds.forEach((draggedSensorId) => {
          setSensorGroup({
            variables: { id: draggedSensorId, sensorGroup: null },
          });
        });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  let hoverStyle = "";

  if (isOver && canDrop) {
    hoverStyle = " bg-primary-400";
  }

  return (
    <div>
      <div
        ref={drop}
        className={`flex py-2 hover:bg-gray-200 ${hoverStyle} pr-4 pl-2 flex items-center`}
      >
        <div className="w-4" />
        <input
          type="checkbox"
          className="cursor-pointer inline-block mr-2 mb-1"
          checked={group.isSelected}
          onChange={() => group.toggle()}
        />
        <span className="text-sm">Incoming</span>
      </div>
    </div>
  );
};
