import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeOwnPasswordForUserMutationVariables = Types.Exact<{
  password: Types.Scalars['String'];
}>;


export type ChangeOwnPasswordForUserMutation = { __typename?: 'Mutation', changeOwnPasswordForUser?: { __typename?: 'changeOwnPasswordForUserPayload', user?: { __typename?: 'User', id: string } | null } | null };

export type ResetOwnTwoFactorAuthForUserMutationVariables = Types.Exact<{
  password: Types.Scalars['String'];
  token: Types.Scalars['String'];
}>;


export type ResetOwnTwoFactorAuthForUserMutation = { __typename?: 'Mutation', resetOwnTwoFactorAuthForUser?: { __typename?: 'resetOwnTwoFactorAuthForUserPayload', user?: { __typename?: 'User', id: string } | null } | null };

export type UpdateSensorCredentialsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  username: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type UpdateSensorCredentialsMutation = { __typename?: 'Mutation', updateSensorCredentials?: { __typename?: 'updateSensorCredentialsPayload', sensorCredentials?: { __typename?: 'SensorCredentials', id: string, username: string, password: string } | null } | null };


export const ChangeOwnPasswordForUserDocument = gql`
    mutation changeOwnPasswordForUser($password: String!) {
  changeOwnPasswordForUser(input: {password: $password}) {
    user {
      id
    }
  }
}
    `;
export type ChangeOwnPasswordForUserMutationFn = Apollo.MutationFunction<ChangeOwnPasswordForUserMutation, ChangeOwnPasswordForUserMutationVariables>;

/**
 * __useChangeOwnPasswordForUserMutation__
 *
 * To run a mutation, you first call `useChangeOwnPasswordForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOwnPasswordForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOwnPasswordForUserMutation, { data, loading, error }] = useChangeOwnPasswordForUserMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangeOwnPasswordForUserMutation(baseOptions?: Apollo.MutationHookOptions<ChangeOwnPasswordForUserMutation, ChangeOwnPasswordForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeOwnPasswordForUserMutation, ChangeOwnPasswordForUserMutationVariables>(ChangeOwnPasswordForUserDocument, options);
      }
export type ChangeOwnPasswordForUserMutationHookResult = ReturnType<typeof useChangeOwnPasswordForUserMutation>;
export type ChangeOwnPasswordForUserMutationResult = Apollo.MutationResult<ChangeOwnPasswordForUserMutation>;
export type ChangeOwnPasswordForUserMutationOptions = Apollo.BaseMutationOptions<ChangeOwnPasswordForUserMutation, ChangeOwnPasswordForUserMutationVariables>;
export const ResetOwnTwoFactorAuthForUserDocument = gql`
    mutation resetOwnTwoFactorAuthForUser($password: String!, $token: String!) {
  resetOwnTwoFactorAuthForUser(input: {password: $password, token: $token}) {
    user {
      id
    }
  }
}
    `;
export type ResetOwnTwoFactorAuthForUserMutationFn = Apollo.MutationFunction<ResetOwnTwoFactorAuthForUserMutation, ResetOwnTwoFactorAuthForUserMutationVariables>;

/**
 * __useResetOwnTwoFactorAuthForUserMutation__
 *
 * To run a mutation, you first call `useResetOwnTwoFactorAuthForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetOwnTwoFactorAuthForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetOwnTwoFactorAuthForUserMutation, { data, loading, error }] = useResetOwnTwoFactorAuthForUserMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetOwnTwoFactorAuthForUserMutation(baseOptions?: Apollo.MutationHookOptions<ResetOwnTwoFactorAuthForUserMutation, ResetOwnTwoFactorAuthForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetOwnTwoFactorAuthForUserMutation, ResetOwnTwoFactorAuthForUserMutationVariables>(ResetOwnTwoFactorAuthForUserDocument, options);
      }
export type ResetOwnTwoFactorAuthForUserMutationHookResult = ReturnType<typeof useResetOwnTwoFactorAuthForUserMutation>;
export type ResetOwnTwoFactorAuthForUserMutationResult = Apollo.MutationResult<ResetOwnTwoFactorAuthForUserMutation>;
export type ResetOwnTwoFactorAuthForUserMutationOptions = Apollo.BaseMutationOptions<ResetOwnTwoFactorAuthForUserMutation, ResetOwnTwoFactorAuthForUserMutationVariables>;
export const UpdateSensorCredentialsDocument = gql`
    mutation updateSensorCredentials($id: ID!, $username: String!, $password: String!) {
  updateSensorCredentials(
    input: {id: $id, username: $username, password: $password}
  ) {
    sensorCredentials {
      id
      username
      password
    }
  }
}
    `;
export type UpdateSensorCredentialsMutationFn = Apollo.MutationFunction<UpdateSensorCredentialsMutation, UpdateSensorCredentialsMutationVariables>;

/**
 * __useUpdateSensorCredentialsMutation__
 *
 * To run a mutation, you first call `useUpdateSensorCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorCredentialsMutation, { data, loading, error }] = useUpdateSensorCredentialsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateSensorCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSensorCredentialsMutation, UpdateSensorCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSensorCredentialsMutation, UpdateSensorCredentialsMutationVariables>(UpdateSensorCredentialsDocument, options);
      }
export type UpdateSensorCredentialsMutationHookResult = ReturnType<typeof useUpdateSensorCredentialsMutation>;
export type UpdateSensorCredentialsMutationResult = Apollo.MutationResult<UpdateSensorCredentialsMutation>;
export type UpdateSensorCredentialsMutationOptions = Apollo.BaseMutationOptions<UpdateSensorCredentialsMutation, UpdateSensorCredentialsMutationVariables>;